// @flow

import React from 'react'
import { css, withStyles } from '../../withStyles';

import QuickReply from './QuickReply'
import QuickReplyColored from './QuickReplyColored'
import Cryptogram from './Cryptogram'
import EventReload from './EventReload'

function MessageType({ styles, message, sendMessage }) {
  if(!message.payload) return <div> { message.message } </div>
  switch(message.payload.type) {
    case 'image': {
      return <img { ...css(styles.image) } src={`${message.payload.url}`} alt="Image sent by bot"/>
    }

    case 'quick_reply':
    case 'quick_reply_colore': {
      return <QuickReply message={message} sendMessage={sendMessage} />
    }

    case 'puzzle_cryptogram': {
      return (
        <Cryptogram message={message} />
      )
    }

    case 'event_reload': {
      return (
        <EventReload/>
      )
    }

    default: {
      return <div> { message.message } </div>
    }
  }
}

export default withStyles(({ extra, color }) => ({
  image: {
    width: '50%'
  }
}))(MessageType)

import React, { Component } from 'react'
import { css, withStyles } from '../../withStyles';

class EventReload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      goingToSleep: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        goingToSleep:  true
      })

      setTimeout(() => {
        window.location.reload()
      }, 4000)

    }, 2000)
  }

  render() {
    return (
      <div {...css(this.props.styles.eventReload, this.state.goingToSleep && this.props.styles.goingToSleep)}>
      </div>
    )
  }
}

const opacityKeyframes = {
    '0%': {
        opacity: 0,
    },

    '100%': {
        opacity: 1,
    }
};

export default withStyles(({ color, extra, unit }) => ({
  eventReload: {
    background: 'black',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
    zIndex: 90000000,
    background: 'url(https://media.giphy.com/media/Q9aBxHn9fTqKs/giphy.gif) no-repeat center/cover'
  },

  goingToSleep: {
    animationName: [opacityKeyframes],
    animationDuration: '3s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
  },

  img: {
    width: '100%',
    height: '100%'
  }
}))(EventReload)

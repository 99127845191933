// @flow

import React from 'react'
import { css, withStyles } from '../../withStyles';

import buffalo from './avatars/user/buffalo.png'
import dog from './avatars/user/dog.png'
import fox from './avatars/user/fox.png'
import panda from './avatars/user/panda.png'
import racoon from './avatars/user/racoon.png'
import tiger from './avatars/user/tiger.png'

const AVATARS = {
  buffalo,
  dog,
  fox,
  panda,
  racoon,
  tiger
}

function AvatarPicker({ styles, handleUserSettingsChanged , currentPhoto }) {
  return (
    <ul { ...css(styles.list )}>
      {
        Object.keys(AVATARS).map((avatar, i) => {
          return (
            // eslint-disable-next-line no-unused-expressions
            <li key={i} { ...css(styles.avatar) } onKeyUp={(e) => { e.keyCode === 13 ? handleUserSettingsChanged('photoURL', AVATARS[avatar]) : null}} role="button" onClick={() => { handleUserSettingsChanged('photoURL', AVATARS[avatar])}} tabIndex={0} >
              <img { ...css(styles.img, currentPhoto===AVATARS[avatar]&&styles.active) } src={AVATARS[avatar]} alt={`${avatar} avatar`} />
            </li>
          )
        })
      }
    </ul>
  )
}

export default withStyles(({ extra, color, unit }) => ({
  list: {
    display: 'flex',
    listStyle: 'none',
    justifyContent: 'center',
    margin: '0 auto 40px auto',
    flexWrap: 'wrap'
  },
  avatar: {
    borderRadius: '50%',
    padding: unit,
    marginBottom: unit/2,

    '@media (min-width: 768px)': {
      margin: unit/2,
    }
  },

  img: {
    width: '60px',
    borderRadius: '50%',
    backgroundColor: color.lightGrey,

    ':hover': {
      borderColor: color.orange,
      borderSize: 3,
      borderStyle:  'solid',
      cursor: 'pointer',
      transform: 'scale(1.3)'
    },
  },
  active: {
    borderColor: color.orange,
    borderSize: 3,
    borderStyle:  'solid',
    transform: 'scale(1.3)',
    ':hover': {
          cursor: 'pointer',
        },
  },

}))(AvatarPicker)

// @flow

import {
  RECORD_USER_READ,
  RECORDS_MESSAGE_NOTED,
  RECORDS_ACHIEVEMENT_NOTED,
  RECORDS_LOADED
} from '../../events'

import type { State, Action } from './types'

function reducer(state: State = { }, action: Action) {
  switch(action.type) {
    case RECORD_USER_READ: {
      return handleRecordUserRead(state, action.payload)
    }
    case RECORDS_LOADED: {
      return handleRecordsLoaded(state, action.payload)
    }

    default: {
      return state
    }
  }
}

function handleRecordsLoaded(state, payload) {
  const { conversationKey, records} = payload
  return {
    ...state,
    [conversationKey]: {
      ...records
    }
  }
}

function handleRecordUserRead(state, payload) {
  const { conversationKey, recordType, recordKey } = payload
  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      [recordType]: {
        ...state[conversationKey][recordType],
        [recordKey]: true
      }
    }
  }
}

export default reducer

// @flow

import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";

import messages from "./features/messages";
import AppController from "./components/AppController";

import * as firebase from "firebase";
import ReactGA from "react-ga";
//UA-112475074-3
ReactGA.initialize("UA-62930496-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const config = {
  apiKey: "AIzaSyCLDP-3YGi03AyYwVhod3Wto23NNNhpjhk",
  authDomain: "snacka-a9047.firebaseapp.com",
  databaseURL: "https://snacka-a9047.firebaseio.com",
  projectId: "snacka-a9047",
  storageBucket: "botbert-8c4e0.appspot.com",
  messagingSenderId: "921341639837"
};

const fb = firebase
  .initializeApp(config)
  .database()
  .ref();

type Props = {};

class App extends Component < Props > {
  componentDidMount() {}

  render() {
    return <AppController / > ;
  }
}

export default App;
export { config }

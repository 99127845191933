import React, { Component } from 'react'
import { css, withStyles } from '../../withStyles';

class DelayedMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fakeTyping: true
    }
  }

  componentDidMount() {
    const { markAsRead, message } = this.props
    setTimeout(() => {
      this.setState({
        fakeTyping: false
      })
      markAsRead(message.conversationKey, 'messages', message.key)
    }, 2800)
  }

  componentDidUpdate() {
    this.props.scrollToBottom()
  }

  render() {
    const { styles } = this.props
    return this.state.fakeTyping ?
      <div {...css(styles.delayedMessage)}>
        <span {...css(styles.dot)}></span>
        <span {...css(styles.dot, styles.dot2)}></span>
        <span {...css(styles.dot, styles.dot3)}></span>
      </div> :
      this.props.children
  }
}

const translateKeyframes = {
  '0%': {
      transform: 'translateY(100%)',
  },

  '30%': {
      transform: 'translateY(0%)',
  },

  '100%': {
      transform: 'translateY(0%)',
  },
}

const slideinKeyframes = {
  '0%': {
      transform: 'translateX(-100%)',
  },

  '30%': {
      transform: 'translateX(0%)',
  },

  '100%': {
      transform: 'translateX(0%)',
  },
}

const bounceKeyframes = {
  '0%': {
		transform: 'initial'
	},

  '60%': {
		transform: 'initial'
	},

  '100%': {
		transform: 'initial'
	},

	'30%': {
		transform: 'translateY(-5px)'
	}
}

export default withStyles(({ color, extra, unit }) => ({
  delayedMessage: {
    borderRadius: unit,
    width: unit * 4,
    height: unit * 3,
    paddingLeft: unit / 4,
    paddingRight: unit / 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    translateY: ('-50%'),
    animationName: [slideinKeyframes],
    animationDuration: '1s',
    animationIterationCount: 1,
    animationFillMode: 'forwards'
  },
  dot: {
    backgroundColor: color.red,
    width: 10,
    height: 10,
    borderRadius: '50%',
    display: 'block',
    margin: unit / 5,
    animationName: [bounceKeyframes],
    animationDuration: '1.3s',
    animationIterationCount: 'infinite',
  },
  dot2: {
    animationDelay: '-1.1s'
  },
  dot3: {
    anitmationDelay: '-0.9s'
  }
}))(DelayedMessage)

// @flow

import type { State } from './types'

type RootState = {
  conversations: { ...State }
}

function getConversation(state: RootState, conversationKey: string) {
  if(!state.conversations[conversationKey]) return null
  return state.conversations[conversationKey]
}

function getCurrentContext(state: RootState, conversationKey: string) {
  if(!state.conversations[conversationKey]) return null
  return state.conversations[conversationKey].context
}


export {
  getConversation,
  getCurrentContext
}

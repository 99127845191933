// @flow

import {
  ACHIEVEMENT_UNLOCKED,
  USER_AUTHENTICATION_SUCCESS,
  USER_UNAUTHENTICATION_SUCCESS,
  USER_INFO_LOADED,
  USER_SETTINGS_UPDATED,
  USER_SETTINGS_CHANGED,
  USER_PROGRESS_UPDATED,
  RESULTS_LOADED
} from '../../events'

import type { Achievement } from '../achievements/types'
import type { State, Action, User } from './types'

function reducer(state: State = { settings: { displayName: '', photoURL: '' } }, action: Action) {
  switch(action.type) {
    case ACHIEVEMENT_UNLOCKED: {
      return handleAchievementUnlocked(state, action.payload)
    }
    case USER_AUTHENTICATION_SUCCESS: {
      return handleUserLogin(state, action.payload)
    }
    case USER_UNAUTHENTICATION_SUCCESS: {
      return handleUserLogout(state, action.payload)
    }
    case USER_INFO_LOADED: {
      return handleUserInfoLoaded(state, action.payload)
    }
    case USER_SETTINGS_UPDATED: {
      return handleUserSettingsUpdated(state, action.payload)
    }
    case USER_SETTINGS_CHANGED: {
      return handleUserSettingsChanged(state, action.payload)
    }
    case  RESULTS_LOADED: {
      return handleResultsLoaded(state, action.payload)
    }
    case USER_PROGRESS_UPDATED: {
      return handleUserProgressUpdated(state, action.payload)
    }
    default: {
      return state
    }
  }
}

function handleAchievementUnlocked(state, payload) {
  const { user, achievement } = payload
  return {
    ...state,
    [user.uid]: {
      ...user,
      achievements: [
        ...state[user.uid].achievements,
        achievement.id
      ]
    }
  }
}

function handleUserLogin(state, payload) {
  const { user } = payload

  if(!user) return state

  return {
    ...state,
    [user.uid]: {
      ...user
    },
    currentUserId: user.uid
  }
}

function handleUserLogout(state, payload) {
  return {
    ...state,
    currentUserId: null
  }
}

function handleUserInfoLoaded(state, payload) {
  const { user } = payload
  let userWithGeneral = {
    ...user
  }

  if(user.initialChatKey) {
    userWithGeneral = {
      ...user,
      chats: {
        ...user.chats,
        ...(state[user.uid] ? state[user.uid].chats : [] ),
        'general': 'hello'
      }
    }
  }

  return {
    ...state,
    [user.uid]: {
      ...state[user.uid],
      ...userWithGeneral
    }
  }
}

function handleUserSettingsUpdated(state, payload) {
  const { settings, userId } = payload

  return {
    ...state,
    [userId]: {
      ...state[userId],
      ...settings
    }
  }
}

function handleUserSettingsChanged(state, payload) {
  const { settings } = payload
  return {
    ...state,
    settings: {
      ...state.settings,
      ...settings
    }
  }
}

function handleResultsLoaded(state, payload) {
  const { userId, results } = payload
  return {
    ...state,
    [userId]: {
      ...state[userId],
      results: {
        ...results
      }
    }
  }
}

function handleUserProgressUpdated(state, payload) {
  const { userId, progress } = payload
  return {
    ...state,
    [userId]: {
      ...state[userId],
      progress: progress
    }
  }
}

export default reducer

// In Dialogflow V2 API, custom payload is unfolded with "type" key. This function restores it into its original form.
function getValue(obj) {
  switch(obj.kind) {
    case "listValue":
      return obj.listValue.values.map(v => getValue(v))
    default:
      return obj[obj.kind]
  }
}

function foldPayload(payload) {
  if (!payload) 
    return undefined

  // Already unfolded
  if (!payload.fields)
    return payload

  const fields = payload.fields
  const folded = {}

  Object.keys(fields).forEach(key => {
    folded[key] = getValue(fields[key])
  })

  return folded
}

export default foldPayload

//@flow

import {
  USER_AUTHENTICATION_SUCCESS,
  USER_UNAUTHENTICATION_SUCCESS,
  USER_INFO_LOADED,
  USER_SETTINGS_UPDATED,
  USER_SETTINGS_CHANGED,
  RESULTS_LOADED,
  USER_PROGRESS_UPDATED
} from '../../events'

import type { User } from './types'
import type { UserSettings } from './types'

function userAuthenticated({ user }: {user: User}) {
  return {
    type: USER_AUTHENTICATION_SUCCESS,
    payload: {
      user
    }
  }
}

function userUnauthenticated() {
  return {
    type: USER_UNAUTHENTICATION_SUCCESS,
    payload: {}
  }
}

function userInfoLoaded({ user }: { user: User }) {
  return {
    type: USER_INFO_LOADED,
    payload: {
      user
    }
  }
}

function userProfileUpdated({ settings, userId }: { settings: UserSettings, userId: string }) {
  return {
    type: USER_SETTINGS_UPDATED,
    payload: {
      settings,
      userId
    }
  }
}

function userSettingsChanged({ settings }: { settings: UserSettings }) {
  return {
    type: USER_SETTINGS_CHANGED,
    payload: {
      settings
    }
  }
}

function resultsLoaded({ userId, results }: { userId: string, results: {} }) {
  return {
    type: RESULTS_LOADED,
    payload: {
      userId,
      results
    }
  }
}

function userProgressUpdated({ userId, progress }: { userId: string, progress: {} }) {
  return {
    type: USER_PROGRESS_UPDATED,
    payload: {
      userId,
      progress
    }
  }
}

export {
  userAuthenticated,
  userUnauthenticated,
  userInfoLoaded,
  userProfileUpdated,
  userSettingsChanged,
  resultsLoaded,
  userProgressUpdated
}

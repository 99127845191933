// @flow

import type { State } from './types'

type RootState = {
  achievements: State
}

function getUnlockedAchievements(state: RootState, userId: string) {
  if(!userId) return null
  return state.achievements[userId]
}

function getAvailableAchievements(state: RootState) {
  return state.achievements.available
}

export {
  getAvailableAchievements,
  getUnlockedAchievements
}

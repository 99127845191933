import React from 'react'
import { css, withStyles } from '../../withStyles'



const Footer = ({ styles }) => (
    <div { ...css(styles.footer) }>
        <p>©&nbsp;2018  PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a href="http://www.pwc.com/structure" target="_blank" rel="noopener noreferrer">www.pwc.com/structure</a> for further details.</p>
        <ul { ...css(styles.links) }>
    
            <li { ...css(styles.link) }><a  href="https://www.pwc.se/integritetspolicy" target="">Integritetspolicy</a></li>
        
            <li { ...css(styles.link) }><a  href="https://www.pwc.se/cookies" target="">Cookies</a></li>
        
            <li { ...css(styles.link) }><a  href="https://www.pwc.com/gx/en/site-information/legal-disclaimer.html" target="">Legal</a></li>
        
            <li{ ...css(styles.link) }><a  href="https://www.pwc.se/siteprovider" target="">Site provider</a></li>
        </ul>
    </div>
)



export default withStyles(({unit, color, extra }) => ({
    footer: {
        fontSize: 10,
        background: 'white',
        width: '100%',
        padding: 12,

        '@media (min-width: 768px)': {
            position: 'absolute',
            bottom: 0,
            left: 0,
        }
    },
    links: {
    display: 'flex',
    color: '#fff',
    listStyle: 'none',
    marginBottom: 0,
    paddingTop: 10,
    },
    link: {
        color: '#000'   ,
        paddingLeft: 12,

        ':first-child': {
            paddingLeft: 0,
        }
    }
}))(Footer)
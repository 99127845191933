import React from 'react'
import { css, withStyles } from '../../withStyles'
import pwcLogo from '../../images/pwc-logo.svg'



const Topbar = ({ styles }) => (
    <div { ...css(styles.topbar) }>
        <img { ...css(styles.img)} src={pwcLogo} alt="PwC Logo" />
    </div>
)



export default withStyles(({unit, color, extra }) => ({
    topbar: {
        background: 'white',
        width: '100%',
        height: 90,

        '@media (min-width: 768px)': {
            position: 'absolute',
        }
      },
    img: {
        display: 'block',
        position: 'relative',
        padding: unit * 1.5,
        minWidth: 62
    },
}))(Topbar)

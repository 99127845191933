// @flow

import type { State } from './types'

type RootState = { records: State }

function getRecords(state: RootState) {
  return state.records
}

export {
  getRecords
}

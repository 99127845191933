// @flow


import React from 'react'
import { css, withStyles } from '../../withStyles'

import ScoreBar from './ScoreBar'

import aras from '../AvatarPicker/avatars/team/axel.png'
import cyberSecurity from '../AvatarPicker/avatars/team/jessica.png'
import tax from '../AvatarPicker/avatars/team/darko.png'
import deals from '../AvatarPicker/avatars/team/johanna.png'
import publicSector from '../AvatarPicker/avatars/team/erik.png'
import consulting from '../AvatarPicker/avatars/team/johanna.png'

const avatars = {
  aras,
  cyberSecurity,
  tax,
  deals,
  publicSector,
  consulting
}

const categoryMapping = {
  cyberSecurity: 'Cyber Security',
  publicSector: 'Public Sector',
  aras: 'Risk Assurance',
}

function ResultScreen({ styles, results, selectedRole }) {
  const arrResults = Object.keys(results).map((key) => {
    return {
      category: categoryMapping[key] || key,
      points: results[key],
      avatar: avatars[key],
      key
    }
  }).sort((resultA, resultB) => {
    return resultA.points > resultB.points ? -1 : 1
  }).filter((result) => {
    if(!selectedRole) return result
    return result.key === selectedRole
  })

  const totalPoints = 3
  const topRole = arrResults[0].category

  return (
    <div {...css(styles.resultScreen)}>
      <div>
        <h1 {...css(styles.title)} > Your results </h1>
      </div>
      {
        arrResults.map((result, index) => {
          const percentage = ((result.points / totalPoints) * 100) + '%'
          return (
            <div {...css(styles.result, topRole === result.category ? styles.winner : styles.loser)}>
              <div {...css(styles.challenge)}> <strong>{result.category}</strong> </div>
              <div {...css(styles.score)}>
                <div {...css(styles.portraitWrapper)} >
                  <img src={result.avatar} alt={`Avatar for ${result.category}`} {...css(styles.portraitWrapper)} />
                </div>
                <ScoreBar percentage={percentage} />
                <div {...css(styles.percentage)}>
                  <strong> {parseInt(percentage)}%</strong>
                </div>
              </div>

            </div>

          )
        })
      }
    </div>
  )
}

const transformKeyFrames = {
  '0%': {
    transform: 'translateX(-100%)',
  },

  '100%': {
    transform: 'translateX(0)',
  }
}

const fadeOutKeyFrames = {
  '0%': {
    opacity: 1,
  },

  '70%': {
    opacity: 1,
  },

  '100%': {
    opacity: 0
  }
}

export default withStyles(({ extra, color, unit }) => ({
  resultScreen: {
    width: '100%',
    height: '100%'
  },
  result: {
    padding: unit
  },
  challenge: {
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  score: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  portraitWrapper: {
    width: 50,
    height: 50,
    marginRight: unit,
    display: 'block',
    borderRadius: '50%',
  },
  portrait: {
    width: '100%',
    height: '100%',
  },
  percentage: {
    marginLeft: 'auto',
    paddingLeft: unit,
    fontSize: 24,
    '@media (min-width: 768px)': {
      paddingLeft: unit * 3,
    },
  },
  winner: {

  },
  loser: {
    animationName: [fadeOutKeyFrames],
    animationDuration: '6s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  }
}))(ResultScreen)

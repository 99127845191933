//@flow

import {
  CONVERSATION_LOADED,
  CONVERSATION_CONTEXT_SET
} from '../../events'

import type { Conversation } from './types'

function conversationLoaded({ conversation, conversationKey }: { conversation: Conversation, conversationKey: string }) {
  return {
    type: CONVERSATION_LOADED,
    payload: {
      conversation,
      conversationKey
    }
  }
}

function conversationContextSet({ context, conversationKey }: { context: String, conversationKey: string }) {
  return {
    type: CONVERSATION_CONTEXT_SET,
    payload: {
      context,
      conversationKey
    }
  }
}

export {
  conversationLoaded,
  conversationContextSet
}

// @flow

import {
  OLD_ACHIEVEMENT_LOADED,
  NEW_ACHIEVEMENT_LOADED,
  UNLOCKED_ACHIEVEMENTS_LOADED
} from '../../events'

import type { State, Action, Achievement } from './types'

const achievements: { [achievmentkey: string]: { ...Achievement } } = {
  "148251fc4907490f8eac2e0f339532a4": {
    id: '9e69d691cb754c66940c44862e213a08',
    icon: 'diamond',
    name: 'Rebel scum',
    text: 'Initiate the attack',
  },
  "9e69d691cb754c66940c44862e213a08": {
    id: '9e69d691cb754c66940c44862e213a08',
    icon: 'diamond',
    name: 'Rebel scum',
    text: 'Initiate the attack',
  },
  "bb083b4f020c4390bc4934585d674496": {
    id: '9e69d691cb754c66940c44862e213a08',
    icon: 'diamond',
    name: 'Rebel scum',
    text: 'Initiate the attack',
  },
  "a6cd4883790d4a2f8132e97ccd2e4916": {
    id: '9e69d691cb754c66940c44862e213a08',
    icon: 'diamond',
    name: 'Rebel scum',
    text: 'Initiate the attack',
  },
  "458d800ab0934e07a9ec16f3e1b3bc16": {
    id: '9e69d691cb754c66940c44862e213a08',
    icon: 'diamond',
    name: 'Rebel scum',
    text: 'Initiate the attack',
  },
  "2a66e207e9c64242b2767d428fdadf4d": {
    id: '9e69d691cb754c66940c44862e213a08',
    icon: 'diamond',
    name: 'Rebel scum',
    text: 'Initiate the attack',
  },
  "7aSPDjhQSKNrzaCqK5LCH5vwJT13": {
    id: '9e69d691cb754c66940c44862e213a08',
    icon: 'diamond',
    name: 'Rebel scum',
    text: 'Initiate the attack',
  }
}

function reducer(state: State = { available: achievements }, action: Action) {
  switch(action.type) {
    case UNLOCKED_ACHIEVEMENTS_LOADED: {
      return handleUnlockedAchievementsLoaded(state, action.payload)
    }
    case OLD_ACHIEVEMENT_LOADED: {
      return handleOldAchievementLoaded(state, action.payload)
    }
    case NEW_ACHIEVEMENT_LOADED: {
      return handleNewAchievementLoaded(state, action.payload)
    }
    default: {
      return state
    }
  }
}

function handleUnlockedAchievementsLoaded(state, payload) {
  const { conversationKey, achievements } = payload
  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      ...achievements
    }
  }
  }

function handleOldAchievementLoaded(state, payload) {
  const { conversationKey, achievement, achievementKey } = payload
  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      [achievementKey]: achievement
    }
  }
}

function handleNewAchievementLoaded(state, payload) {
  const { conversationKey, achievement, achievementKey } = payload
  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      [achievementKey]: {
        ...achievement,
        new: true
      }
    }
  }
}


export default reducer

import React, { Component } from 'react'
import { css, withStyles } from '../../withStyles'

class CookieDisclaimer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cookiesAccepted: localStorage.getItem('cookiesAccepted') || false
    }
  }
  render() {
    const { styles } = this.props
    if(this.state.cookiesAccepted) return null
    return (
      <div { ...css(styles.cookieContainer)}>
        <div { ...css(styles.cookieDisclaimer)}>
          <p> This site uses cookies to improve the experience. <a {...css(styles.link)} href="https://www.pwc.se/integritetspolicy">Read More</a></p>
          <div style={{ textAlign: 'center' }}>
            <button { ...css(styles.button)} onClick={this.acceptCookies}> I understand </button>
          </div>
        </div>
      </div>
    )
  }

  acceptCookies = () => {
    this.setState({ cookiesAccepted: true })
    localStorage.setItem('cookiesAccepted', 'true');
  }

}

const transformKeyFrames = {
  '0%': {
    transform: 'translateY(-100%)',
  },

  '100%': {
    transform: 'translateY(0)',
  }
}

export default withStyles(({unit, color, extra }) => ({
  cookieContainer: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: 50,
    zIndex: 9000,
  },
  cookieDisclaimer: {
    background: 'white',
    textAlign: 'center',
    maxWidth: 500,
    margin: '0 auto',
    color: color.grey,
    padding: unit,

    'a:visited': {
      color: 'white'
    },


    'p': {
      textAlign: 'center'
    }

  },
  link: {
    ':link': {
      color: color.grey
    },
    ':visited': {
      color: color.grey
    }
  },
  button: {
    fontSize: unit,
    backgroundColor: '#d04a02',
    border: 'none',
    padding: '10px 35px',
    color: '#FFF',
    marginTop: unit,

    ':hover': {
      cursor: 'pointer'
    }
  }
}))(CookieDisclaimer)

import React from "react";
import { css, withStyles } from "../../withStyles";

import Loader from "../Loader/Loader";
import Conversation from "./Conversation";

function Conversations({
  styles,
  conversations,
  handleSetCurrentConversation,
  currentConversationKey,
  usersUiSettings,
  achievements,
  unlockedAchievements,
  chatsWithUnread
}) {
  return (
    <ul {...css(styles.conversations)}>
      {conversations
        ? conversations.map((conversation, idx) => {
            return conversation ? (
              <Conversation
                key={idx}
                conversation={conversation}
                isActive={currentConversationKey === conversation.key}
                handleSetCurrentConversation={handleSetCurrentConversation}
                usersUiSettings={usersUiSettings}
                achievements={achievements}
                unlockedAchievements={unlockedAchievements}
                hasUnread={chatsWithUnread[conversation.key]}
              />
            ) : null;
          })
        : null}
    </ul>
  );
}

export default withStyles(({ unit, color, extra }) => ({
  conversations: {
    listStyle: "none",
    background: color.grey,
    minWidth: "280px",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    height: "100%",

    "@media (min-width: 768px)": {
      minWidth: "180px"
    },

    "::-webkit-scrollbar": {}
  }
}))(Conversations);

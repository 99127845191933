import React from 'react'
import ReactDOM from 'react-dom'
import 'font-awesome/css/font-awesome.css'
import './defaultStyles.js'; // global styles through Aphrodite
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { Provider, connect } from 'react-redux'
import store from './store'



require('./ApiAi.js')



ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'))
registerServiceWorker()

import React, { Component } from 'react'
import { css, withStyles } from '../../withStyles'

import Timer from '../Timer/Timer'


class QuickReplyColored extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timer: null,
      answerSent: null
    }
  }

  componentDidMount() {
    const timer = this.props.handleDecreaseTimer()
    this.setState({ timer: timer })
  }

  componentWillUnmount() {
    if(!this.state.answerSent && this.props.currentTime !== null) {
      this.handleSendMessage('...')
    }
  }

  render() {
    const { styles, currentReplies, sendMessage, currentTime, handleDecreaseTimer } = this.props
    if(!currentReplies.replies) return null
    return (
      <div {...css(styles.list)}>
        {currentTime ? <Timer decreaseTimer={handleDecreaseTimer} currentTime={currentTime} /> : null }
        {
          currentReplies.replies.map((reply) => {
            const [replyText, color] = reply.split(':')
            return <div {...css(styles.reply, styles[color])} onClick={() => { this.handleSendMessageByClick(replyText) }} tabIndex="0"> { replyText } </div>
          })
        }
      </div>
    )
  }


    handleSendMessage = (replyText) => {
      const { sendMessage } = this.props
      this.setState({ answerSent:  true })
      clearInterval(this.state.timer)
      sendMessage(replyText)
    }

    handleSendMessageByClick = (replyText) => {
      const { sendMessage } = this.props
      this.setState({ answerSent:  true }, () => {
        clearInterval(this.state.timer)
        sendMessage(replyText)
      })
    }
}

export default withStyles(({ extra, color, unit }) => ({
  list: {
    display: 'flex',
  },
  reply: {
    padding: unit,
    color: '#999FA3',
    backgroundColor: '#FFF',
    border: '1px solid #E5E7E8',
    margin: unit,
    marginTop: unit * 2,
    width: '100%',
    textAlign: 'center',
    ':hover': {
    cursor: 'pointer',
    background: '#FFF7E5',
    border: '1px solid #FFB600',
    color: '#333F48',
    }
  },

  red: {
    color: `${color.red}`,
    border: `1px solid ${color.red}`,

    ':hover': {
      border: `1px solid ${color.red}`,
      color: `${color.red}`,
    }
  },
  orange: {
    color: `${color.orange}`,
    border: `1px solid ${color.orange}`,

    ':hover': {
      border: `1px solid ${color.orange}`,
      color: `${color.orange}`,
    }
  },
  blue: {
    color: 'blue',
    border: '1px solid blue',

    ':hover': {
      border: '1px solid blue',
      color: 'blue'
    }
  },
  yellow: {
    color: `${color.yellow}`,
    border: `1px solid ${color.yellow}`,

    ':hover': {
      border: `1px solid ${color.yellow}`,
      color: `${color.yellow}`,
    }
  },
  green: {
    color: 'green',
    border: '1px solid green',

    ':hover': {
      border: '1px solid green',
      color: 'green'
    }
  },
  black: {
    color: `${color.grey}`,
    border: `1px solid ${color.grey}`,

    ':hover': {
      border: `1px solid ${color.grey}`,
      color: `${color.grey}`,
    }
  }
}))(QuickReplyColored)

// @flow
import React from 'react'
import { css, withStyles } from '../../withStyles';

import aras from '../AvatarPicker/avatars/team/transparent/axel.png'
import tax from '../AvatarPicker/avatars/team/transparent/darko.png'
import deals from '../AvatarPicker/avatars/team/transparent/johanna.png'
import cyberSecurity from '../AvatarPicker/avatars/team/transparent/jessica.png'
import publicSector from '../AvatarPicker/avatars/team/transparent/erik.png'
import consulting from '../AvatarPicker/avatars/team/transparent/johanna.png'

import axel from '../AvatarPicker/avatars/team/real/axel.jpg'
import erik from '../AvatarPicker/avatars/team/real/erik.jpg'
import darko from '../AvatarPicker/avatars/team/real/darko.jpg'
import jessica from '../AvatarPicker/avatars/team/real/jessica.jpg'
import johanna from '../AvatarPicker/avatars/team/real/johanna.jpg'

import { roles } from './roles'
import ShareResults from './ShareResults'

const avatars = {
  aras,
  cyberSecurity,
  tax,
  deals,
  publicSector
}

const photos = {
  aras: axel,
  cyberSecurity: jessica,
  tax: darko,
  deals: johanna,
  publicSector: erik
}

function RoleScreen({ styles, roleKey, user, selectedRole, setSelectedRole, initialTopRole }) {
  const r = selectedRole || roleKey
  const roleIndex = Object.keys(avatars).indexOf(r)
  const role = roles[r]
  return (
    <div {...css(styles.roleScreen)}>
      <div {...css(styles.roleHeading)}>
        <div {...css(styles.roleAvatars)}>
          <div {...css(styles.roleAvatar)}>
            <img {...css(styles.avatar)} src={user.photoURL} alt="User avatar" />
          </div>
          <div {...css(styles.roleAvatar)}>
            <img {...css(styles.avatar)} src={avatars[r]} alt="Avatar for selected role" />
          </div>
        </div>
        { !selectedRole && <p {...css(styles.paragraph)}> {user.displayName}, you would be perfect in </p>}
        <h1  {...css(styles.roleTitle)}> {role.title} </h1>
        <div  {...css(styles.roleTags)}>
          {
            role.tags.map((tag) => <div  {...css(styles.roleTag)}> {tag} </div>)
          }
        </div>
        { !selectedRole && <p {...css(styles.paragraph)}> I know, spot on right? Spooky! </p> }
        <p {...css(styles.meet)}> See more below to meet the real person behind the role. </p>
      </div>
      <div  {...css(styles.roleBody)}>

        <div {...css(styles.allAvatars)}>
          <span {...css(styles.roleChanger)} role="button" onClick={() => {
              const newIndex = roleIndex - 1 < 0 ? Object.keys(avatars).length - 1 : roleIndex - 1
              setSelectedRole(Object.keys(avatars)[newIndex])
            }}> <i className="fa fa-chevron-left"></i> </span>
          {
            Object.keys(avatars).map((key) => {
              return (
                <div role="button" onClick={() => setSelectedRole(key)} {...css(styles.roleAvatarInList, r === key ? styles.selected : {})}>
                  <img {...css(styles.avatar, styles.inList)} src={avatars[key]} alt={`avatar for ${key}`} />
                </div>
              )
            })
          }
          <span {...css(styles.roleChanger)} role="button" onClick={() => {
              const newIndex = roleIndex + 1 === Object.keys(avatars).length ? 0 : roleIndex + 1
              setSelectedRole(Object.keys(avatars)[newIndex])
            }}> <i className="fa fa-chevron-right"></i> </span>
        </div>
        <div  {...css(styles.roleBox)} >
          <div  {...css(styles.roleBoxProfile)}>
            <div>
              <img {...css(styles.rolePhoto)} src={photos[r]} alt="Real photo of person in selected role"></img>
            </div>
            <div {...css(styles.roleBio)}>
              <p {...css(styles.smallHeading)}>{role.fullName}</p>
              <p>{role.jobTitle}</p>
              <p {...css(styles.smallHeading)}>EDUCATION</p>
              <p>{role.education}</p>
            </div>
          </div>
          <div  {...css(styles.roleBoxText)}>
            <h3 {...css(styles.heading)}> Did you take any course while at school that have benefited you in your job?</h3>
            <p {...css(styles.paragraph)}>
              {role.backgroundStory}
            </p>
            <h3 {...css(styles.heading)}> Do you have any personal traits or knowledge that have been beneficial for you in your job?</h3>
            <p {...css(styles.paragraph)}>
              {role.roleText}
            </p>
            <h3 {...css(styles.heading)}> What is the best thing about your job? </h3>
            <p {...css(styles.paragraph)}>
              {role.best}
            </p>
            <h3 {...css(styles.heading)}> What do you like at PwC?</h3>
            <p {...css(styles.paragraph)}>
              {role.whyPwc}
            </p>
          </div>
        </div>
        <p { ...css(styles.tryAgain)}>
          {"Want to have another go? "} <a { ...css(styles.tryAgainLink)} href="/"> Try the internship again</a>
        </p>
      </div>
      <div { ...css(styles.roleFooter)}>
        <h3> More about PwC </h3>
        <div { ...css(styles.actionBox)}>
          <div { ...css(styles.action)}>
            <span { ...css(styles.actionIcon)}><i className="fa fa-android"></i></span>
            <span { ...css(styles.actionTitle)}> PwC Chatbot </span>
            <p { ...css(styles.actionText)}>Do you have any questions about PwC? Give our friendly Chatbot a try</p>
            <a { ...css(styles.link)} href="https://www.facebook.com/pwckarriar/">Chat with the bot</a>
          </div>
          <div { ...css(styles.action)}>
            <span { ...css(styles.actionIcon)}><i className="fa fa-graduation-cap"></i></span>
            <span { ...css(styles.actionTitle)}> PwC Student Club </span>
            <p { ...css(styles.actionText)}>Register to stay up to speed on vacancies, current events and competitions at PwC</p>
            <a { ...css(styles.link)} href="https://www.pwc.se/student#mc-embedded-subscribe-form">Sign up here</a>
          </div>
        </div>
      </div>
      <ShareResults initialTopRole={roles[roleKey].title} />
    </div>
  )
}

const transformKeyFrames = {
  '0%': {
    transform: 'translateY(-100%)',
  },

  '100%': {
    transform: 'translateY(0)',
  }
}

export default withStyles(({ extra, color, unit }) => ({
  roleScreen: {
    background: 'white',
    animationName: [transformKeyFrames],
    animationDuration: '2s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
  roleHeading: {
    color: 'white',
    backgroundColor: '#d04a02',
    fontSize: 34,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: unit * 5,
    textAlign: 'center'
  },
  roleAvatars: {
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'center',
    paddingBottom: unit
  },
  roleAvatar: {
    borderRadius: '50%',
    width: 150,
    height: 150,
    display: 'block',
    position: 'relative',
    zIndex: 1,
    marginRight: -unit,

    ':first-of-type': {
      zIndex: 3
    },
  },
  avatar: {
    borderRadius: '50%',
    width: 150,
    height: 150,
    background: color.lightGrey,
    border: `4px solid white`
  },
  roleTitle: {
    fontSize: 54,
    padding:  unit,
    fontFamily: 'Georigia, Sans'
  },
  roleTags: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    margin: '0 auto',

    '@media (min-width: 768px)': {
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: unit * 2,
      justifyContent: 'center',
    }

  },
  roleTag: {
    backgroundColor: '#d04a02',
    margin: unit / 2,
    color: 'white',
    borderRadius: unit * 4,
    border: '3px solid white',
    paddingTop: unit,
    paddingBottom: unit,
    paddingLeft: unit * 4,
    paddingRight: unit * 4,
    textAlign: 'center',
    fontSize: 26
  },
  paragraph: {
    padding: unit * 2,
    paddingTop: unit,

    ':last-of-type': {
      paddingBottom: unit * 3
    }
  },
  heading: {
    color: '#d04a02',
    '@media (min-width: 768px)': {
      paddingLeft: unit * 2,
    }
  },
  roleBody: {
    padding: unit,

    '@media (min-width: 768px)': {
      padding: unit * 3,
    }
  },
  meet: {
    textAlign: 'center',
    paddingBottom: 20,
    fontSize: 30
  },
  allAvatars: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  roleAvatarInList: {
    borderRadius: '50%',
    width: 80,
    height: 80,
    display: 'block',
    position: 'relative',
    zIndex: 1,
    margin: unit,

    'img': {
      border: 0
    },

    ':first-of-type': {
      zIndex: 3
    },
  },
  roleChanger: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    height: 40,
    width: 40,
    fontSize: unit * 2,
    borderRadius: '50%',
    color: '#d04a02',
    fontWeight: 'bold',
    border: '2px solid #d04a02',
    marginTop: 10,

    ':first-of-type': {
      paddingRight: 5,
      marginRight: unit,
    },

    ':last-of-type': {
      paddingLeft: 5,
      marginLeft: unit
    },

    '@media (min-width: 768px)': {
      display: 'flex'
    }
  },
  selected: {
    width: 80,
    height: 80,

    ':after': {
      content: "''",
      background: '#d04a02',
      display: 'block',
      position: 'absolute',
      left: -(unit / 2),
      top: -(unit / 2),
      padding: unit / 2,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      zIndex: -1,

      '@media (min-width: 768px)': {
        background: '#d04a02',
      }
    }
  },
  inList: {
    width: 80,
    height: 80,
  },
  roleBox: {
    display: 'flex',
    flexDirection: 'column',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      border: '3px solid #d04a02',
      paddingTop: unit * 3,
      marginTop: -(unit * 4),
      paddingBottom:  85,
    }
  },
  roleBoxProfile: {
    display: 'flex',
    padding: unit * 2,
    flexDirection: 'column',
    alignItems: 'center',

    '@media (min-width: 768px)': {
      width: '40%',
      borderRight: `1px solid ${color.lightGrey}`
    }
  },
  roleBoxText: {
    '@media (min-width: 768px)': {
      paddingTop:  85,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '80%'
    }
  },
  rolePhoto: {
    width: 150,
    height: 150,
    borderRadius: '50%'
  },
  roleBio: {
    width: '100%',
    padding: unit * 2
  },
  smallHeading: {
    color: '#d04a02',
    paddingTop: unit,
  },
  roleFooter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: unit * 5,
    backgroundColor: '#d04a02',
    color: 'white'
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
    }
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    padding: unit * 3,
    textAlign: 'center',
    maxWidth: 500,
    '@media (min-width: 768px)': {
      width: '50%',
    }
  },
  actionIcon: {
    fontSize: unit * 3,
    color: '#d04a02',
    backgroundColor: 'white',
    margin: '0 auto',
    marginBottom: unit * 2,
    width: 75,
    height: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%'
  },
  actionTitle: {
    paddingBottom: unit,
    fontWeight: 'bold'
  },
  actionText: {
    paddingBottom: unit,
  },
  share: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  list: {
    listStyle: 'none',
    padding: 0,
    display: 'flex'
  },
  listItem: {
    color: '#FFF',
    margin: 10,
    backgroundColor: 'black',
    width : 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%'
  },
  link: {
    color: 'white',
  },

  fb: {
    backgroundColor: '#3b5998'
  },
  twitter: {
    backgroundColor: '#00aced'
  },
  linkedIn: {
    backgroundColor: '#0077b5'
  },
  gplus: {
    backgroundColor: '#dd4b39'
  },
  tryAgain: {
    paddingTop: unit * 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: unit * 2,
    textAlign: 'center',
    paddingBottom: unit,

    '@media (min-width: 768px)': {
      flexDirection: 'row'
    }
  },
  tryAgainLink: {
    color: '#d04a02',
    marginLeft: unit
  }
}))(RoleScreen)

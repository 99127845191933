// @flow

import {
  RECORD_USER_READ,
  RECORDS_LOADED
} from '../../events'

function recordsLoaded({ conversationKey, records }: {conversationKey: string, records: {}}) {
  return {
    type: RECORDS_LOADED,
    payload: {
      conversationKey,
      records
    }
  }
}

function recordUserRead({ conversationKey, recordType, recordKey  }: {conversationKey: string, recordType: string, recordKey: string}) {
  return {
    type: RECORD_USER_READ,
    payload: {
      conversationKey,
      recordType,
      recordKey
    }
  }
}

export {
  recordsLoaded,
  recordUserRead
}

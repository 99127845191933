// @flow

import React from 'react'
import { css, withStyles } from '../../withStyles';

function NameField({ handleUserSettingsChanged, userSettings}) {
  return (
    <input type="text" class="inputName" onChange={(e) => handleUserSettingsChanged('displayName', e.target.value) } value={userSettings.displayName || ''} />
  )
}

export default withStyles(({ extra, color }) => ({

}))(NameField)

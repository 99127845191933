// @flow

import React from 'react'
import { css, withStyles } from '../../withStyles'


type Props = {
  onAuthenticate: () => void,
  authenticating: bool,
  disabled: bool,
}

function Login({ onAuthenticate, authenticating, disabled, styles }) {
  return (
    <button onClick={onAuthenticate} {...css(styles.loginButton, disabled && styles.disabled )} tabIndex="0">
      <div  {...css(styles.action)}>
        { !authenticating ? <span>Begin</span> : <span> Loading <span { ...css(styles.dot) }></span><span { ...css(styles.dot, styles.dot2) }></span><span { ...css(styles.dot, styles.dot3) }></span> </span> }
      </div>
    </button>

  )
}

const bounceKeyframes = {
  '0%': {
		transform: 'initial'
	},

  '60%': {
		transform: 'initial'
	},

  '100%': {
		transform: 'initial'
	},

	'30%': {
		transform: 'translateY(-5px)'
	}
}

export default withStyles(({ color, unit }) => ({
  login: {
    borderTopRightRadius: '50%',
    borderTopLeftRadius: '50%',
    width: 100,
    height: 100,
    backgroundColor: color.secondary,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: unit * 2,
    color: '#4885ed'
  },
  action: {

  },
  loginButton: {
    fontSize: unit * 2,
    background: '#d04a02',
    border: 'none',
    padding: '10px 35px',
    color: '#FFF',
    display: 'inline-block',
    ':hover': {
      background: color.grey,
      cursor: 'pointer',
    }
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none'
  },
  dot: {
    backgroundColor: 'white',
    width: 10,
    height: 10,
    borderRadius: '50%',
    display: 'inline-block',
    margin: unit / 5,
    animationName: [bounceKeyframes],
    animationDuration: '1.3s',
    animationIterationCount: 'infinite',
  },
  dot2: {
    animationDelay: '-1.1s'
  },
  dot3: {
    anitmationDelay: '-0.9s'
  }
}))(Login)

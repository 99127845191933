// @flow

import {
  CONVERSATION_LOADED,
  CONVERSATION_CONTEXT_SET
} from '../../events'

import type { State, Action } from './types'

const inititalState = {
  'general': {
    accessKey: null,
    timestamp: 1519315152783,
    title: "General",
    key: "general",
  }
}

function reducer(state: State = inititalState, action: Action) {
  switch(action.type) {
    case CONVERSATION_LOADED: {
      return handleConversationLoaded(state, action.payload)
    }
    case CONVERSATION_CONTEXT_SET: {
      return handleConversationContextSet(state, action.payload)
    }
    default: {
      return state
    }
  }
}

function handleConversationLoaded(state, payload) {
  const { conversationKey, conversation } = payload

  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      ...conversation,
      key: conversationKey
    }
  }
}

function handleConversationContextSet(state, payload) {
  const { conversationKey, context } = payload

  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      context
    }
  }
}
export default reducer

// @flow

import React from 'react'
import { css, withStyles } from '../withStyles'

import Achievements from './Achievements/Achievements'
import Desktop from './Desktop/Desktop'
import Loader from './Loader/Loader'
import Windowed from './Window/Windowed'
import ConversationApp from './ConversationApp/ConversationApp'
import ResultsScreen from './ResultScreen/ResultScreen'
import LoginPuzzle from './LoginPuzzle/LoginPuzzle'
import colourlogo from '../images/pwc-logo.svg'
import Intro from './Common/Intro'
import Outro from './Common/Outro'
import CookieDisclaimer from './Common/CookieDisclaimer'
import Topbar from './Topbar/Topbar'
import Footer from './Footer/Footer'


import desktopbg from '../images/background_blurred.jpg'

function AppView(props) {
  if(!props.started)return <Intro handleUserStart={props.handleUserStart}/>
  return (
    <div {...css(props.styles.room)}>
      {!props.enteredPassword && <div {...css(props.styles.overlay)}></div>}
      { /* The room div is where we will run the video background loop */ }
      <div {...css(props.styles.computer)}>
        {(!props.enteredPassword || !props.chats.length || !props.currentConversationKey ) && <Topbar />}
        { /* The computer div is where we will run everything that is on the computer desktop, will have the computer background image*/ }
        { props.enteredPassword ? (
          <Desktop
            handleOpenChat={() => props.handleToggleCurrentUi('chat')}
            handleOpenTrash={() => props.handleToggleCurrentUi('note')}
            handleOpenBrowser={() => props.handleToggleCurrentUi('achievements')}>
            { props.userResults && <Outro results={props.userResults} user={props.currentUser} /> }
            <Content {...props} />
          </Desktop>
        ) : (
          <div {...css(props.styles.loginScreen)}>
            <strong {...css(props.styles.enterPassword)}>Please enter your password</strong>
            <LoginPuzzle password={props.passwordSequence} handleUserEnteredPassword={props.handleUserEnteredPassword} />
          </div>
        ) }
      </div>
      <CookieDisclaimer />
      <Footer />
    </div>
  )
}

function Content(props) {
  const {
    achievements,
    unlockedAchievements,
    currentUi,
    handleMoveUiComponent,
    handleToggleCurrentUi,
  } = props
  return <div>
    { currentUi.chat.toggled ? (
      <Windowed
        onMove={handleMoveUiComponent('chat')} x={currentUi.chat.x} y={currentUi.chat.y}
        onClose={() => handleToggleCurrentUi('chat')}
        >
        <ConversationApp {...props} />
      </Windowed>
      ) : null
    }
    { currentUi.note.toggled ? (
      <Windowed
        onMove={handleMoveUiComponent('note')} x={currentUi.note.x} y={currentUi.note.y}
        onClose={() => handleToggleCurrentUi('note')}
        >
        <div> Your password is: 1234 </div>
      </Windowed>
      ) : null
    }
    {
      currentUi.achievements.toggled ? (
        <Windowed
          onMove={handleMoveUiComponent('achievements')} x={currentUi.achievements.x} y={currentUi.achievements.y}
          onClose={() => handleToggleCurrentUi('achievments')}
          >
          <Achievements achievements={achievements} unlockedAchievements={unlockedAchievements || {}} />
        </Windowed>
      ) : null
    }
  </div>

}

const opacityKeyframes = {
    '0%': {
        opacity: 1,
    },

    '98%': {
        opacity: 0,
    },

    '100%': {
        opacity: 0,
    }
};

const transitionKeyframes = {
    '0%': {
      zIndex: 1
    },

    '99%': {
      zIndex: 1
    },

    '100%': {
      zIndex: -1
    }
};

export default withStyles(({ color, unit }) => ({
  app: {
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    margin: '0 auto'
  },
  chat: {
    display: 'flex',
    maxWidth: 1200,
    margin: '0 auto',
  },
  enterPassword: {
    fontSize: unit * 2
  },
  sidebar: {
    display: 'none',
    display: 'flex',
    flexDirection: 'column'
  },
  closebutton: {
    float: 'right'
  },
  bio: {
    marginBottom: 'auto'
  },
  room: {

  },
  computer: {
    height: '100vh',
    width: '100vw',
    background: `url(${desktopbg}) no-repeat center center fixed`,
    backgroundSize: 'cover',

  },
  overlay: {
    '@media (min-width: 768px)': {
      position: 'absolute',
      background: 'black',
      width: '100vw',
      height: '100vh',
      animationName: [opacityKeyframes, transitionKeyframes],
      animationDuration: '3s',
      animationIterationCount: '1',
      animationFillMode: 'forwards',
      zIndex: 1
    }
  },
  loginScreen: {
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
  },
  pwcLogo: {
    width: 150,
    height: 150,
    background: 'white',
    borderRadius: '50%',
    padding: unit * 2,
    margin: '0 auto',
    marginBottom: unit * 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pwcLogoColour: {
    width: '100%',
  }
}))(AppView)

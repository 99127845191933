import React, { Component } from 'react'
import { css, withStyles } from '../../withStyles'
import ResultScreen from '../ResultScreen/ResultScreen'
import RoleScreen from '../RoleScreen/RoleScreen'


class Outro extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clickedResults: false,
      selectedRole: '',
      initialTopRole: ''
    }
  }
  render() {
    const { styles, results, user } = this.props

    const arrResults = Object.keys(results).map((key) => {
      return {
        category: key,
        points: results[key]
      }
    })

    const topRole = arrResults.sort((resultA, resultB) => {
      return resultA.points > resultB.points ? -1 : 1
    })[0].category

    if(!this.state.initialTopRole) {
      this.setState({ initialTopRole: topRole })
    }

    return (
      <div { ...css(styles.outro)}>
        <div { ...css(styles.centerContent)}>
          <div { ...css(styles.main)}>
            { this.state.clickedResults ? (
              <div { ...css(styles.resultsContainer)}>
                <ResultScreen results={results} selectedRole={this.state.selectedRole} />
                { this.state.showRoleScreen ? (<div { ...css(styles.roleScreenContainer)} >
                  <RoleScreen roleKey={topRole} user={user} selectedRole={this.state.selectedRole} setSelectedRole={this.setSelectedRole} initialTopRole={this.state.initialTopRole} />
                </div>) : null }
              </div>
            ) : (
              <div>
                <h1 { ...css(styles.title)}> The End</h1>
                <div style={{ textAlign: 'left' , maxWidth: 800, margin: '0 auto'}}>
                  <p { ...css(styles.paragraph)}>
                    Well… kind of. This is more like the post credits bit that everyone sits and waits around for after a superhero movie... but this time, it's actually worth it - Promise.
                  </p>
                  <p { ...css(styles.paragraph)}>
                    ‘Why?’ did I hear you ask? Because … now all of your hard work is going to pay off and you will find out where you fit in at PwC. Cool right?
                  </p>
                </div>
                <p { ...css(styles.paragraph, styles.leftAlign)}>
                  <a role="button" onClick={() => {
                      this.setState({ clickedResults: true })
                      setTimeout(() => {
                        this.setState({ showRoleScreen: true })
                      }, 7000)
                    }} { ...css(styles.cta, styles.mainCTA)}>Show me my results!</a>
                </p>
              </div>
            ) }
          </div>

          <footer>
            <ul { ...css(styles.list)}>
              <li { ...css(styles.listItem)}><a href=""></a><i class="fa fa-facebook-square"></i></li>
              <li { ...css(styles.listItem)}><a href=""></a><i class="fa fa-twitter-square"></i></li>
              <li { ...css(styles.listItem)}><a href=""></a><i class="fa fa-instagram"></i></li>
            </ul>
          </footer>
        </div>
      </div>
    )
  }

  setSelectedRole = (role) => {
    this.setState({ selectedRole: role })
  }

}

const transformKeyFrames = {
  '0%': {
    transform: 'translateY(-100%)',
  },

  '100%': {
    transform: 'translateY(0)',
  }
}

export default withStyles(({unit, color, extra }) => ({
  outro: {
    background: 'rgba(0,0,0,0.6)',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    willChange: 'transform',
    animationName: [transformKeyFrames],
    animationDuration: '2s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    position: 'relative',
    zIndex: 900
  },
  centerContent: {
    background: 'white',
    fontFamily: 'Arial, sans-serif',
    color: '#3F3F40',
    fontSize: 20,
    fontWeight: 300,
    maxWidth: 1200,
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -35%)',
    textAlign: 'center',

    '@media (min-width: 768px)': {
        width: '90%',
        maxWidth: 1000
    },
  },
  title: {
    color: '#d04a02',
    fontWeight: 300,
    fontSize: 60,
    marginBottom: 30,
    fontFamily: 'Georgia',
    textAlign: 'left',
    maxWidth: 800,
    margin: '0 auto',
  },
  titleIntro: {
    fontSize: 20,
    display: 'block',
    color: '#3F3F40',
    fontFamily: 'Arial, sans-serif',
  },
  leftAlign: {
    textAlign: 'left',
    margin: '0 auto',
    maxWidth: 800,
  },
  cta: {
    fontSize: 24,
    background: '#3F3F40',
    border: 'none',
    padding: '10px 35px',
    color: '#FFF',
    maxWidth: 400,
    textAlign: 'center',
  },
  main: {
    background: '#FFFFFF',
    padding: 50,
    position: 'relative',
  },
  mainCTA: {
    backgroundColor: '#d04a02',
  },
  mainCTA: {
    fontSize: 24,
    backgroundColor: '#d04a02',
    border: 'none',
    padding: '10px 35px',
    color: '#FFF',
    marginTop: 30,
    marginBottom: -20,
  },
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0'
  },
  list: {
    listStyle: 'none'
  },
  listItem: {
    color: '#FFF',
    display: 'inline-block',
    margin: 10
  },
  InfoIcon: {
    display: 'block',
    width: 60,
    lineHeight: 60,
    height: 60,
    position: 'absolute',
    top: -60,
    right: -60,
    fontSize: 25,
    color: '#FFFFFF'
  },
  resultsContainer: {
    background: 'white',
    left: 0,
    top: 0,
    width: '100%',
  },
  roleScreenContainer: {
    overflow: 'hidden',
    position: 'absolute',
    left: 0,
    top: 200,
    width: '100%',
  }
}))(Outro)

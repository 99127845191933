// @flow

import {
  NEW_ACHIEVEMENT_LOADED,
  OLD_ACHIEVEMENT_LOADED,
  UNLOCKED_ACHIEVEMENTS_LOADED
} from '../../events'

import type { Achievement } from './types'

function unlockedAchievementsLoaded({ conversationKey, achievements }: { conversationKey: string, achievements: Array<Achievement>}) {
  return {
    type: UNLOCKED_ACHIEVEMENTS_LOADED,
    payload: {
      conversationKey,
      achievements
    }
  }
}

function oldAchievementLoaded({ conversationKey, achievement, achievementKey }: { conversationKey: string, achievement: Achievement, achievementKey: string }) {
  return {
    type: OLD_ACHIEVEMENT_LOADED,
    payload: {
      conversationKey,
      achievement,
      achievementKey
    }
  }
}

function newAchievementLoaded({ conversationKey, achievement, achievementKey }: { conversationKey: string, achievement: Achievement, achievementKey: string }) {
  return {
    type: NEW_ACHIEVEMENT_LOADED,
    payload: {
      conversationKey,
      achievement,
      achievementKey
    }
  }
}

export {
  newAchievementLoaded,
  oldAchievementLoaded,
  unlockedAchievementsLoaded
}

// @flow

import type { State } from './types'

type RootState = {
  users: { ...State }
}

function getChats(state: RootState, userId: string) {
  return userId && state.users[userId] ? state.users[userId].chats : {}
}

function getCurrentUser(state: RootState, userId: string) {
  return state.users && state.users[userId] || null
}

function getSettings(state: RootState) {
  return state.users.settings
}

function getUiSettings(state: RootState) {
  return Object.keys(state.users).reduce((acc,key) => {
    if(!state.users[key] || !state.users[key].displayName) return acc
    acc[key] = {
      ...state.users[key]
    }
    return acc
  }, {})
}

function getUserResults(state: RootState, userId: string) {
  return state.users[userId] && state.users[userId].results || null
}

export {
  getChats,
  getCurrentUser,
  getSettings,
  getUiSettings,
  getUserResults
}

export default {
  color: {
    green: '#3EB890', //Slacker green
    blue: '#70CADB', //Slack blue
    yellow: '#FFB600', //PwC yellow
    red: '#E0301E', //PwC RED
    pink: '#E01765',  //Slack pinkish
    orange: '#d04a02', //PwC Organge
    grey: '#3F3F40', //PwC Grey
    lightGrey: '#DFDFDF', //PwC Light grey

    primary: '#FFF',
    secondary: '#3F3F40', //PwC brown


  },

  extra: {
    borderRadius: 4
  },

  unit: 12
}

// @flow

import type { State } from './types'

type RootState = { ui: State }

function getCurrentUi(state: RootState) {
  return state.ui.currentUi
}

function getCurrentReplies(state: RootState, currentConversationKey) {
  return state.ui.replies && state.ui.replies[currentConversationKey] || []
}

function getCurrentTimer(state: RootState, currentConversationKey: string) {
  return state.ui.timers && state.ui.timers[currentConversationKey] || null
}

export {
  getCurrentUi,
  getCurrentReplies,
  getCurrentTimer
}

// @flow

import {
  SET_CURRENT_UI,
  UI_COMPONENT_MOVED,
  QUICK_REPLIES_LOADED,
  TIMER_SET,
  TIMER_DECREASE,
  MESSAGE_CLEARED
} from '../../events'

import type { State, Action } from './types'

function reducer(state: State = { currentUi: { conversationList: { toggled: false }, achievements: {}, chat: { toggled: true }, note: {}, conversationBio: {} }, timers: {} }, action: Action, ) {
  switch(action.type) {
    case SET_CURRENT_UI: {
      return handleToggleCurrentUi(state, action.payload)
    }

    case UI_COMPONENT_MOVED: {
      return handleUiComponentMoved(state, action.payload)
    }

    case QUICK_REPLIES_LOADED: {
      return handleQuickRepliesLoaded(state, action.payload)
    }

    case TIMER_SET: {
      return handleTimerSet(state, action.payload)
    }

    case TIMER_DECREASE: {
      return handleTimerDecrease(state, action.payload)
    }

    case MESSAGE_CLEARED: {
      return handleClearQuickReplies(state, action.payload)
    }


    default: {
      return state
    }
  }
}

function handleToggleCurrentUi(state, payload) {
  const { uiKey } = payload

  const toggle = !state.currentUi[uiKey].toggled

  return {
    ...state,
    currentUi: {
      ...state.currentUi,
      [uiKey]: {
        ...state.currentUi[uiKey],
        toggled: toggle
      }
    }
  }
}

function handleUiComponentMoved(state, payload) {
  const { uiKey, x, y } = payload

  return {
    ...state,
    currentUi: {
      ...state.currentUi,
      [uiKey]: {
        ...state.currentUi[uiKey],
        x,
        y
      }
    }
  }
}

function handleTimerSet(state, payload) {
  const { conversationKey } = payload

  return {
    ...state,
    timers: {
      ...state.timers,
      [conversationKey]: 10
    }
  }
}

function handleTimerDecrease(state, payload) {
  const { conversationKey } = payload
  return {
    ...state,
    timers: {
      ...state.timers,
      [conversationKey]: state.timers[conversationKey] - 1
    }
  }
}

function handleQuickRepliesLoaded(state, payload) {
  const { conversationKey, replies, modifier } = payload

  return {
    ...state,
    replies: {
      ...state.replies,
      [conversationKey]: {
        modifier: modifier || null,
        replies
      }
    }
  }
}

function handleClearQuickReplies(state, payload) {
  const { chatId } = payload

  return {
    ...state,
    replies: {
      ...state.replies,
      [chatId]: []
    }
  }
}

export default reducer

// @flow
import React, { Component } from 'react'
import { css, withStyles } from '../../withStyles';

type Props = {
  onClose: Function,
  onMove: Function
}



class Windowed extends Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { styles, children, x, y, onClose } = this.props
    return (
      <div {...css(styles.chatWindow)}>
        <div data-key="topbar" {...css(styles.topbar)}>
          <p {...css(styles.toolbarTitle)}>PwC Chat Software</p>
        </div>
        <div {...css(styles.content)}>
            { children }
        </div>
      </div>

    )
  }
1
  drag = (event) =>  {
    let shiftX = event.clientX - event.target.getBoundingClientRect().left;
    let shiftY = event.clientY - event.target.getBoundingClientRect().top;

    const { onMove } =  this.props
    if(event.target.dataset.key !== 'topbar') return

    function moveAt(pageX, pageY,) {
      onMove(pageX - shiftX, pageY - shiftY)
    }

    function onMouseMove(event) {
      const {x, y} = event.target.getBoundingClientRect()
      moveAt(event.pageX, event.pageY)
    }

    document.addEventListener('mousemove', onMouseMove)

    document.onmouseup = function() {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }
}


export default withStyles(({ extra, color, unit }) => ({
  chatWindow: {
    zIndex: -1,

    '@media (min-width: 768px)': {
      width: '80%',
      maxWidth: '1400px',
      boxShadow: '0px 0px 60px rgba(0,0,0,0.8)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '6px',
    }
  },
  topbar: {
    display: 'none',
    padding: unit,
    background: '#eeedee',
    background: 'linear-gradient(to bottom, #eeedee 0%,#dad9d9 99%)',
    borderBottom: '1px solid #999FA3',
    borderRadius: '6px 6px 0 0',

    '@media (min-width: 768px)': {
      display: 'flex',
    }
  },
  content: {
    userSelect: 'none',
  },
  closeButton: {
    background: '#ff2443',
    background: 'linear-gradient(to bottom, #ff2443 0%,#e31b37 100%)',
    width: '18px',
    height: '18px',
    border: 'none',
    borderRadius: '50%',
    textIndent: '100%',
	  whiteSpace: 'nowrap',
	  overflow: 'hidden',
    ':hover': {
      cursor: 'pointer',
      background: '#ff2443'
    }
  },
  toolbarTitle: {
    textAlign: 'center',
    display: 'inline-block',
    color: '#626469',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.5);',
    width: '100%',
    fontSize: '15px',
  }

}))(Windowed)

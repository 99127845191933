import React from 'react'
import { css, withStyles } from '../../withStyles';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share'

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share'

const ShareResults = ({ styles, initialTopRole }) => {
  return (
    <div  {...css(styles.share)}>
      <p> Share your results </p>
        <ul { ...css(styles.list)}>
          <li { ...css(styles.listItem)}>
            <FacebookShareButton
              url={"https://theinternship.pwc.se"}
              quote={`I became a ${initialTopRole} in PwC's The Internship, what about you?`}
              hashtag={'#pwctheinternship'}
            >
              <FacebookIcon size={36} round />
            </FacebookShareButton>
          </li>
          <li { ...css(styles.listItem)}>
            <LinkedinShareButton
              url={"https://theinternship.pwc.se"}
              description={`I became a ${initialTopRole} in PwC's The Internship, what about you?`}
              title={'PwC - The Internship'}
            >
              <LinkedinIcon size={36} round />
            </LinkedinShareButton>
          </li>
          <li { ...css(styles.listItem)}>
            <TwitterShareButton
              url={"https://theinternship.pwc.se"}
              title={`I became a ${initialTopRole} in PwC's The Internship, what about you?`}
              hashtags={['pwctheinternship']}
            >
              <TwitterIcon size={36} round />
            </TwitterShareButton>
          </li>
        </ul>
    </div>
  )
}

export default withStyles(({ extra, color, unit }) => ({
  share: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  list: {
    listStyle: 'none',
    padding: 0,
    display: 'flex'
  },
  listItem: {
    margin: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})) (ShareResults)

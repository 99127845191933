import { combineReducers } from 'redux'

import achievements from './achievements/reducer'
import messages from './messages/reducer'
import records from './records/reducer'
import users from './users/reducer'
import conversations from './conversations/reducer'
import ui from './ui/reducer'

const reducers = combineReducers({
  achievements,
  messages,
  records,
  users,
  conversations,
  ui
})

export default reducers

// @flow

import type { State, Message } from './types'

type RootState = { messages: State }
type Chats = {}

function getCurrentMessage(state: RootState ): string {
  return state.messages.currentMessage
}

function getMessagesForChats(state: RootState, chats: Chats) {
  if(!chats || !Object.keys(chats).length) return []
  return Object.keys(chats).map((chatKey: string) => {
    return { key: chatKey, messages: state.messages[chatKey] }
  })
}

function getCurrentConversationKey(state: RootState) {
  return state.messages.currentConversationKey
}

function hasUnreadForChat(state: RootState, currentUserId: string) {
  return Object.keys(state.messages).reduce((acc, chatKey) => {
    acc[chatKey] = !Object.values(state.messages[chatKey]).find((message) => {
      return message.isUser
    })
    return acc
  }, {})
}

export {
  getCurrentMessage,
  getMessagesForChats,
  getCurrentConversationKey,
  hasUnreadForChat
}

import React from 'react'
import { css, withStyles } from '../../withStyles';

function Achievement({ styles, achievement, unlocked, isNew }) {
  return (
    <div { ...css(styles.achievement, isNew && styles.unlockingAnimationAchievement, unlocked ? styles.unlocked : styles.locked) }>
      <div { ...css(styles.badge, isNew && styles.unlockingAnimationBadge, unlocked && styles.unlockedBage) }>
        {unlocked ?
          <i className={`fa fa-lock fa-2x`}></i>:
          <i className={`fa fa-lock fa-2x`}></i>
        }
      </div>
    </div>
  )
}

const unlockingKeyFramesAchievement = {


}

const unlockingKeyframesBadge = {
  '0%': {
    transform: 'rotate(0) translateX(0)',
  },

  '90%': {
    transform: 'rotate(-360deg) translateX(0) ',
  },

  '100%': {
    transform: 'rotate(-360deg) translateX(-100%) ',
  }
}

export default withStyles(({ color, extra, unit }) => ({
  achievement: {
    display: 'flex',
    padding: '12px 12px 12px 20px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',

    ':first-child': {

    },
    ':last-child': {
      borderBottom: 0,
    }
  },
  info: {
    display: 'flex',
    flexDirection: 'column'
  },
  badge: {
    width: 50,
    height: 50,
    fontWeight: 800,
    borderRadius: '50%',
    backgroundColor: color.primary,
    marginRight: unit * 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.secondary,
    transition: 'background-color 300ms ease-in-out'
  },
  locked: {
    opacity: '0.8',
  },
  unlocked: {
    backgroundColor: color.tertiary,
    color: color.white
  },
  unlockedBage: {
    backgroundColor: color.white
  },
  unlockingAnimationAchievement: {
    animationName: [unlockingKeyFramesAchievement],
    animationDuration: '3.5s',
    animationIterationCount: 1,
    animationFillMode: 'forwards'
  },
  unlockingAnimationBadge: {
    animationName: [unlockingKeyframesBadge],
    animationDuration: '1.4s',
    animationIterationCount: 1,
    animationFillMode: 'forwards'
  }

}))(Achievement)

// @flow

import React from "react";
import { css, withStyles } from "../../withStyles";
import Achievement from "../Achievements/Achievement";

function Conversation({
  styles,
  conversation,
  handleSetCurrentConversation,
  isActive,
  usersUiSettings,
  achievements,
  unlockedAchievements,
  hasUnread
}) {
  const unlocked =
    unlockedAchievements[conversation.accessKey] &&
    unlockedAchievements[conversation.accessKey].unlocked;
  const isNew =
    unlockedAchievements[conversation.accessKey] &&
    unlockedAchievements[conversation.accessKey].new;
  const isGeneral = conversation.key === "general";
  return (
    <li
      {...css(
        styles.conversation,
        isActive && styles.active,
        unlocked || isGeneral ? null : styles.locked,
        unlocked || isGeneral ? null : styles.disabled
      )}
      onClick={() =>
        unlocked || isGeneral
          ? handleSetCurrentConversation(conversation.key)
          : null
      }
      role="button"
    >
      <div {...css(styles.container)}>
        <div
          {...css(
            styles.avatar,
            isNew && styles.isNew,
            isGeneral && styles.isGeneral
          )}
        >
          <span
            {...css(
              styles.onlineIndiciator,
              isActive && styles.onlineIndiciator_active,
              !unlocked && styles.onlineIndiciator_inactive,
              unlocked && hasUnread && styles.onlineIndiciator_unread
            )}
          >
            {" "}
            {unlocked && hasUnread ? <i className="fa fa-envelope" /> : ""}{" "}
          </span>
          {usersUiSettings[conversation.accessKey] ? (
            <img
              {...css(styles.image)}
              src={usersUiSettings[conversation.accessKey].photoURL}
              alt="Avatar in chatlist"
            />
          ) : (
            "G"
          )}
        </div>
        <span>{conversation.title}</span>
      </div>
    </li>
  );
}

const transformKeyFrames = {
  "0%": {
    transform: "scale(1)"
  },

  "20%": {
    transform: "scale(1.2)"
  },

  "40%": {
    transform: "scale(1)"
  },

  "60%": {
    transform: "scale(1.2)"
  },

  "100%": {
    scale: "translateY(0)"
  }
};

export default withStyles(({ color, extra, unit }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "12px 12px 12px 20px"
  },
  conversation: {
    color: "white",
    position: "relative",
    ":hover": {
      background: color.lightGrey,
      cursor: "pointer"
    }
  },
  active: {
    background: color.lightGrey
  },
  avatar: {
    position: "relative",
    width: unit * 4,
    height: unit * 4,
    borderRadius: "50%",
    background: color.primary,
    marginRight: unit / 2
  },
  onlineIndiciator: {
    alignItems: "center",
    justifyContent: "center",
    fontSize: unit,
    position: "absolute",
    top: -5,
    right: -5,
    display: "none",
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    textAlign: "center"
  },
  onlineIndiciator_active: {},
  image: {
    width: "100%",
    borderRadius: "50%"
  },
  locked: {
    opacity: "0.4"
  },
  onlineIndiciator_inactive: {
    backgroundColor: "#f9f9f9"
  },
  onlineIndiciator_unread: {
    backgroundColor: color.red,
    display: "flex"
  },
  isNew: {
    animationName: [transformKeyFrames],
    animationDuration: "1.3s",
    animationIterationCount: 2
  },
  isGeneral: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: color.red,
    fontSize: unit * 2,
    background: "white"
  },
  disabled: {
    pointerEvents: "none"
  }
}))(Conversation);

import React from 'react'
import { css, withStyles } from '../../withStyles'
import desktopbg from '../../images/background.jpg'
import desktopbgBlur from '../../images/background_blurred.jpg'
import CookieDisclaimer from './CookieDisclaimer'
import Topbar from '../Topbar/Topbar'
import Footer from '../Footer/Footer'


class Intro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialLoadCount: 0
    }
  }
  render() {
    const { styles, handleUserStart } = this.props
    const { initialLoadCount } = this.state
    console.log(initialLoadCount)

    if(initialLoadCount < 2) {
      return (
        <div>
          <img style={{ display: 'none' }}  src={desktopbg} onLoad={() => { this.setState({ initialLoadCount: initialLoadCount + 1 })}}/>
          <img style={{ display: 'none' }} src={desktopbgBlur} onLoad={() => { this.setState({ initialLoadCount: initialLoadCount + 1 })}}/>
        </div>

      )
    }

    return (
      <div { ...css(styles.intro)}>
        <Topbar />
        <div { ...css(styles.centerContent)}>
          <main { ...css(styles.main)}>
            <h1 { ...css(styles.title)}><span {...css(styles.titleIntro)}>Get ready to experience...</span> The Internship</h1>
            <div style={{ textAlign: 'left' , maxWidth: 800, margin: '0 auto'}}>
              <p { ...css(styles.paragraph)}>Ever wondered what it would feel like to be an intern at PwC?</p>
              <p { ...css(styles.paragraph)}> Of course you have! Through the magic of modern technology and a fictitious office chat software simulation, you are just about to try it out!</p>
              <p { ...css(styles.paragraph)}>First things first, let’s get you logged into your PwC desktop.</p>
            </div>
            <p { ...css(styles.paragraph, styles.leftAlign)}>
              <button onClick={handleUserStart} { ...css(styles.mainCTA)} tabIndex="0">Log me in already!</button>
            </p>
          </main>

          <footer>
            <ul { ...css(styles.list)}>
              <li { ...css(styles.listItem)}><a { ...css(styles.link)} href="https://www.facebook.com/pwckarriar/"><i className="fa fa-facebook-square"></i></a></li>
              <li { ...css(styles.listItem)}><a { ...css(styles.link)} href="https://twitter.com/pwc_sverige?lang=en"><i className="fa fa-twitter-square"></i></a></li>
              <li { ...css(styles.listItem)}><a { ...css(styles.link)} href="https://www.instagram.com/pwckarriar_sweden/?hl=en"><i className="fa fa-instagram"></i></a></li>
            </ul>
          </footer>
        </div>
        <Footer />
        <CookieDisclaimer />
      </div>
    )
  }
}
export default withStyles(({unit, color, extra }) => ({
  intro: {
    background: `url(${desktopbg}) no-repeat center center fixed, url(${desktopbgBlur}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
  },
  centerContent: {
    background: 'white',
    fontFamily: 'Arial, sans-serif',
    color: '#3F3F40',
    fontSize: 20,
    fontWeight: 300,
    maxWidth: 1200,
    width: '100%',
    textAlign: 'center',

    '@media (min-width: 768px)': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -35%)',
      width: '90%',
      maxWidth: 1000
    },
  },
  main: {
    background: '#FFFFFF',
    padding: 50,
    position: 'relative',
  },
  title: {
    color: '#d04a02',
    fontWeight: 300,
    fontSize: 60,
    marginBottom: 30,
    fontFamily: 'Georgia',
    textAlign: 'left',
    maxWidth: 800,
    margin: '0 auto',
  },
  titleIntro: {
    fontSize: 20,
    display: 'block',
    color: '#3F3F40',
    fontFamily: 'Arial, sans-serif',
  },
  mainCTA: {
    fontSize: 24,
    backgroundColor: '#d04a02',
    border: 'none',
    padding: '10px 35px',
    color: '#FFF',
    marginTop: 30,
    marginBottom: -20,
  },
  paragraph: {
    margin: '20px 0',
    lineHeight: 1.4
  },
  leftAlign: {
    textAlign: 'left',
    margin: '0 auto',
    maxWidth: 800,
  },
  list: {
    listStyle: 'none'
  },
  listItem: {
    color: '#FFF',
    display: 'inline-block',
    margin: 10,
  },

  link: {
    ':link': {
      color: 'white'
    },
    ':visited': {
      color: 'white'
    }
  },

  infoIcon: {
    display: 'block',
    width: 60,
    lineHeight: 60,
    height: 60,
    position: 'absolute',
    top: -60,
    right: -60,
    fontSize: 25,
    color: '#FFFFFF'
  }
}))(Intro)

// @flow

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { css, withStyles } from '../../withStyles'

import Message from '../Message/Message'
import Loader from '../Loader/Loader'
import Achievements from '../Achievements/Achievements'
import Timer from '../Timer/Timer'

import QuickReply from '../Message/QuickReply'
import QuickReplyColored from '../Message/QuickReplyColored'
import QuickReplyTimed from '../Message/QuickReplyTimed'

type Props = {
  messages: Array<object>,
  achievements: Array<object>,
  unlockedAchievements: Array<object>
}

class Chat extends Component {
  constructor(props) {
    super(props)
    this.scrollToBottom = this.scrollToBottom.bind(this)
  }
  render() {
    const { styles, achievements, unlockedAchievements, message, messages, messageChanged, sendMessage, markAsRead, currentUser, usersUiSettings, currentReplies, talkingTo, handleToggleConversationBio, handleDecreaseTimer, currentTime } = this.props
    if(!messages) return null
    const messageKeyArr = Object.keys(messages)
    return (
      <div {...css(styles.container)}>
        <div {...css(styles.chatTitle)}>
          <div {...css(styles.chatTitleWrap)}>
            <span {...css(styles.chatTitleOnline)}><i className={'fa fa-circle'}></i></span>
            <h1 {...css(styles.chatTitleHeader)}>{ talkingTo ? talkingTo.displayName : 'Loading...' }</h1>
            <span  {...css(styles.chatTitleRole)}> {talkingTo ? talkingTo.bio.role : ''} </span>
          </div>
        </div>
        <div {...css(styles.messageWrapper)}>
          <div {...css(styles.messageContainer)}
            ref={(el) => {
              this.messageContainer = el
            }}>
            { messageKeyArr.length ? messageKeyArr.map((messageKey) => (
              <Message key={messageKey}
                message={messages[messageKey]}
                isNew={messages[messageKey].new}
                scrollToBottom={this.scrollToBottom}
                sendMessage={sendMessage}
                markAsRead={markAsRead}
                currentUser={currentUser}
                usersUiSettings={usersUiSettings} />
              )
            ): null }
          </div>
          <div  {...css(styles.messageBox)}>
            { currentReplies.modifier === null &&   <QuickReply currentReplies={currentReplies} sendMessage={sendMessage} /> }
            { currentReplies.modifier === 'color' &&   <QuickReplyColored handleDecreaseTimer={handleDecreaseTimer} currentTime={currentTime} currentReplies={currentReplies} sendMessage={sendMessage} /> }
            { currentReplies.modifier === 'timed' &&  <QuickReplyTimed handleDecreaseTimer={handleDecreaseTimer} currentTime={currentTime} currentReplies={currentReplies} sendMessage={sendMessage} /> }
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    setTimeout(this.scrollToBottom, 1000)
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  handleKeyUp = (event) => {
    const { sendMessage } = this.props
    if(event.keyCode !== 13) return
    sendMessage()
  }

  scrollToBottom = () => {
    if(!this.messageContainer) return
    this.messageContainer.scrollTop = this.messageContainer.scrollHeight
  }
}

export default withStyles(({ color, unit }) => ({
  achievements: {
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative'
  },
  chatTitle: {
    background: '#FFFFFF',
    borderBottom: '1px solid #E5E7E8',
    height: 70,
    width: '100%',
    zIndex: 4,
    position: 'fixed',

    '@media (min-width: 768px)': {
      position: 'relative',
      width: 'auto'
    },
  },
  chatTitleWrap: {
    margin: '0 auto',
    justifyContent: 'center',
    display: 'flex',
    padding: '0 0 0 20px',

    '@media (min-width: 768px)': {
      justifyContent: 'flex-start'
    },
  },
  chatTitleHeader: {
    fontSize: '20px',
    color: '#3F3F40',
    lineHeight: '72px',
    paddingRight: unit
  },
  chatTitleRole: {
    fontSize: '20px',
    fontStyle: 'italic',
    color: '#3F3F40',
    lineHeight: '72px',
    display: 'inline-block',
    '@media (min-width: 768px)': {
    },
  },
  chatTitleOnline: {
    color: '#86DC1F',
    lineHeight: '72px',
    margin: '0 10px 0 20px',
    display: 'inline-block',
    '@media (min-width: 768px)': {
      float: 'left',
    },
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
    height: '100%',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflowY: 'scroll',
    padding: unit,
    paddingTop: 75,
    backgroundColor: color.primary,
    flex: '1 0 0',
    height: '100%',

    '@media (min-width: 768px)': {
      padding: unit,
    },
  },
  messageBox: {
    width: '100%',
    backgroundColor: 'white',
    borderTop: '1px solid #E5E7E8',
    padding: unit / 3,
    minHeight: 77,
    position: 'relative'
  },
  textArea: {
    width: '100%',
    border: 0,
    backgroundColor: 'white',
    boxSizing: 'border-box',
    resize: 'none'
  },
  button: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    color: color.darkestGreyDark,
    fontSize: 16,
    fontWeight: '700',
    border: 0,

    ':hover': {
      color: color.darkestGreyDark,
    }
  }
}))(Chat)

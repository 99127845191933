// @flow

import React from 'react'
import { css, withStyles } from '../../withStyles';

function Cryptogram({ styles, message }) {
  return (
    <div>
      <div> {message.payload.value[0].question}. </div>
      <div>We know that {message.payload.value[0].clue}. Can you solve it? </div>
    </div>
  )
}

export default withStyles(({ extra, color, unit }) => ({
  list: {
    display: 'flex',
  },
  reply: {
    padding: unit,
    color: color.primary,
    backgroundColor: color.yellow,
    margin: unit,
    width: '100%',
    cursor: 'pointer'
  }
}))(Cryptogram)

// @flow

import * as actions from './actions'
import * as selectors from './selectors'
import * as service from './service'

export default {
  actions,
  selectors,
  service
}

// @flow

import React from "react";
import { css, withStyles } from "../../withStyles";

type Props = {
  styles: any,
  person: {
    photoURL: string,
    bio: {
      name: string,
      role: string,
      description: string
    }
  }
};

function ConversationBio(props: Props) {
  if (!props.person) return null;
  const { bio, photoURL } = props.person;
  const { styles } = props;

  return (
    <div {...css(styles.container)}>
      <div {...css(styles.avatar)}>
        <img
          {...css(styles.img)}
          src={photoURL}
          alt={`Avatar for ${bio.name}`}
        />
      </div>
      <div {...css(styles.overflowContainer)}>
        <h2 {...css(styles.name)}> {bio.name} </h2>
        <p {...css(styles.role)}>
          {" "}
          <i> {bio.role} </i>{" "}
        </p>
        <p {...css(styles.description)}> {bio.description} </p>
        <h3 {...css(styles.interestsHeading)}> Interests </h3>
        {bio.interests ? (
          <ul {...css(styles.interests)}>
            {bio.interests.map((interest, index) => (
              <li key={index} {...css(styles.interest)}>
                {" "}
                <p>{interest}</p>{" "}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
}

export default withStyles(({ extra, color, unit }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: unit / 2,
    height: "100vh",
    color: "black",

    "@media (min-width: 768px)": {
      maxWidth: 300,
      height: "100%"
    }
  },

  overflowContainer: {
    overflowY: "scroll",
    padding: unit,
    background: "white",
    height: "100%"
  },

  avatar: {
    width: 137,
    height: 200,
    alignSelf: "center",
    display: "flex"
  },

  name: {
    fontSize: unit * 1.2,
    fonstStyle: "bold",
    textAlign: "center"
  },

  role: {
    paddingBottom: unit,
    fontSize: unit,
    textAlign: "center",
    color: color.orange,
    paddingTop: unit / 2
  },

  description: {
    fontSize: unit
  },

  img: {
    width: "100%"
  },
  interestsHeading: {
    color: color.orange,
    textTransform: "uppercase",
    paddingTop: unit * 2,
    fontWeight: 300,
    fontSize: unit
  },
  interests: {
    paddingTop: unit,
    listStyle: "none",
    fontSize: unit
  },
  interest: {
    listStyle: "none",
    display: "flex",
    paddingBottom: unit,

    ":before": {
      content: '"• "',
      marginRight: 4,
      display: "block",
      color: color.orange
    }
  }
}))(ConversationBio);

//@flow

import {
  MESSAGE_CHANGED,
  MESSAGE_CLEARED,
  MESSAGE_SENT,
  OLD_MESSAGE_LOADED,
  NEW_MESSAGE_LOADED,
  MESSAGES_LOADED,
  SET_CURRENT_CONVERSATION,
  RECORD_USER_READ
} from '../../events'

import type { State, Action, Message } from './types'

/*
Axel: Hi everyone! BIG congrats to Jane on her birthday today!
Johanna: yääy *cake emoji*
Erik: Congratulations, Jane!
Jessica: *birthday gif*
Darko: woop woop! *cake emoji*
Jane: Thanks! *smiley*
Jane: By the way, do any of you know where I can find the link to the referral app? I have a great person I want to recommend for a job!
Darko: Yes, I can e-mail you the link.
Jane: Thanks! Speaking of great people, today we have a new start coming in to the office! Be nice everyone and make sure to pop in a say hi in DM
*/


/* Person keys */
const pkMapping = {
  'axel': '148251fc4907490f8eac2e0f339532a4',
  'fredrik': '9e69d691cb754c66940c44862e213a08',
  'jessica': 'bb083b4f020c4390bc4934585d674496',
  'erik': 'a6cd4883790d4a2f8132e97ccd2e4916',
  'johanna': '458d800ab0934e07a9ec16f3e1b3bc16',
  'darko': '2a66e207e9c64242b2767d428fdadf4d'
}


const initialState = {
  currentMessage: '',
  general: {
    "1": {
      message: "Hi everyone! BIG congrats to Fredrik on his birthday today!",
      senderId: pkMapping.axel,
      timestamp: 1519315153306
    },
    "2": {
      message: "yääy 🎂",
      senderId: pkMapping.johanna,
      timestamp: 1519315153306
    },
    "3": {
      message: '',
      senderId: pkMapping.jessica,
      timestamp: 1519315153306,
      payload: {
        type: 'image',
        url: 'https://media.giphy.com/media/YTbZzCkRQCEJa/giphy.gif'
      }
    },
    "4": {
      message: "Congratulations Fredrik",
      senderId: pkMapping.erik,
      timestamp: 1519315153306
    },
    "5": {
      message: "woop woop! 🎂",
      senderId: pkMapping.darko,
      timestamp: 1519315153306
    },
    "6": {
      message: "Thanks 😊",
      senderId: pkMapping.fredrik,
      timestamp: 1519315153306
    },
    "7": {
      message: "Hey, I’m having trouble with our new iPhone X! Where is the home button!?",
      senderId: pkMapping.erik,
      timestamp: 1519315153306
    },
    "8": {
      message: "lol, come join us in 2018 😉. Swiping is the new black!",
      senderId: pkMapping.johanna,
      timestamp: 1519315153306
    },
    "9": {
      message: "have any of you tried the new career chatbot? It’s called Jobbot and you can chat with in on Facebook or at www.pwc.se/student.",
      senderId: pkMapping.axel,
      timestamp: 1519315153306
    },
    "10": {
      message: "Totally missed that, but thanks will check! What does it do?",
      senderId: pkMapping.erik,
      timestamp: 1519315153306
    },
    "11": {
      message: "Answer questions about PwC Sweden, you can search for jobs and apply, you can sign up to the Student Newsletter and do a pop quiz!",
      senderId: pkMapping.axel,
      timestamp: 1519315153306
    },
    "12": {
      message: " Go Jobbot! 🤖",
      senderId: pkMapping.johanna,
      timestamp: 1519315153306
    },
    "13": {
      message: "Oh, and Jakob says hello from Australia! He started his global secondment and just arrived at the PwC HQ in Sydney and is having a blast!",
      senderId: pkMapping.johanna,
      timestamp: 1519315153306
    },
    "14": {
      message: "Woho, say hi from me!",
      senderId: pkMapping.darko,
      timestamp: 1519315153306
    },
    "15": {
      message: "The Force is strong in that one!",
      senderId: pkMapping.axel,
      timestamp: 1519315153306
    },
    "16": {
      message: "Put another shrimp on the barbey! hehehe",
      senderId: pkMapping.fredrik,
      timestamp: 1519315153306
    },
    "17": {
      message: "...",
      senderId: pkMapping.jessica,
      timestamp: 1519315153306
    },
    "18": {
      message: "No, Fredrik. Just no.",
      senderId: pkMapping.jessica,
      timestamp: 1519315153306
    },
    "19": {
      message: " I’ll see myself out. By the way, do any of you know where I can find the link to the referral app? I have a great person I want to recommend for a job!",
      senderId: pkMapping.fredrik,
      timestamp: 1519315153306
    },
    "20": {
      message: "Also, we have a new intern joining us today so don't be afraid to say hello!",
      senderId: pkMapping.fredrik,
      timestamp: 1519315153306
    },
  }
}

function reducer(state: State = initialState, action: Action) {
  switch(action.type) {
    case MESSAGE_CHANGED: {
      return handleMessageChanged(state, action.payload)
    }
    case MESSAGE_CLEARED: {
      return handleMessageCleared(state, action.payload)
    }
    case MESSAGE_SENT: {
      return handleMessageSent(state, action.payload)
    }
    case OLD_MESSAGE_LOADED: {
      return handleOldMessageLoaded(state, action.payload)
    }
    case NEW_MESSAGE_LOADED: {
      return handleNewMessageLoaded(state, action.payload)
    }
    case RECORD_USER_READ: {
      return handleMarkAsRead(state, action.payload)
    }
    case MESSAGES_LOADED: {
      return handleMessagesLoaded(state, action.payload)
    }
    case SET_CURRENT_CONVERSATION: {
      return handleSetCurrentConversation(state, action.payload)
    }

    default: {
      return state
    }
  }
}

function handleMessageChanged(state: State, payload) {
  const { message } = payload
  return {
    ...state,
    currentMessage: message
  }
}

function handleMessageCleared(state: State, payload) {
  return {
    ...state,
    currentMessage: ''
  }
}

function handleMessageSent(state: State, payload) {
  return {
    ...state,
    message: 'store setup'
  }
}

function handleOldMessageLoaded(state: State, payload) {
  const { conversationKey, message, messageKey } = payload
  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      [messageKey]: message
    }
  }
}

function handleNewMessageLoaded(state: State, payload) {
  const { conversationKey, message, messageKey } = payload
  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      [messageKey]: {
        ...message,
        new: true,
        key: messageKey,
        conversationKey: conversationKey
      }
    }
  }
}

function handleMessagesLoaded(state: State, payload) {
  const { conversationKey, messages } = payload
  return {
    [conversationKey]: {
      ...messages
    }
  }
}

function handleSetCurrentConversation(state: State, payload) {
  const { conversationKey } = payload
  return {
    ...state,
    currentConversationKey: conversationKey
  }
}

function handleMarkAsRead(state: State, payload) {
  const { conversationKey, recordType, recordKey } = payload
  return {
    ...state,
    [conversationKey]: {
      ...state[conversationKey],
      [recordKey]: {
        ...state[conversationKey][recordKey],
        new: false
      }
    }
  }
}

export default reducer

// @flow

import React from 'react'
import { css, withStyles } from '../../withStyles'

import Achievements from '../Achievements/Achievements'
import AvatarPicker from '../AvatarPicker/AvatarPicker'
import Chat from '../Chat/Chat'
import Conversations from '../Conversations/Conversations'
import ConversationBio from '../ConversationBio/ConversationBio'
import Loader from '../Loader/Loader'
import Login from '../Login/Login'
import NameField from '../NameField/NameField'
import logo from '../../images/pwc-logo.png'
import colourlogo from '../../images/pwc-logo.svg'

function ConversationApp(props) {
  const {
    styles,
    achievements,
    chats,
    conversations,
    currentConversationKey,
    currentUser,
    currentUi,
    currentReplies,
    currentTime,
    message,
    userSettings,
    usersUiSettings,
    unlockedAchievements,
    authenticating,
    handleAuthenticate,
    handleUnauthenticate,
    handleSendMessage,
    handleMessageChanged,
    handleMarkAsRead,
    handleSetCurrentConversation,
    handleUserSettingsChanged,
    handleToggleCurrentUi,
    handleDecreaseTimer,
    chatsWithUnread,
    indicateProgress
  } = props

  const chat = chats.find((chat) => chat.key === currentConversationKey)
  const conversationBio =  conversations.find((c) => c && c.key === currentConversationKey)
  const talkingTo = conversationBio && usersUiSettings[conversationBio.accessKey]

  if((!chats.length || !currentConversationKey) && currentUi.chat.toggled) {
    return (
      <div {...css(styles.login)}>
        <div {...css(styles.picker)}>
          <label {...css(props.styles.nameLabel)}>Please enter your name:</label>
          <input type="text" name="Display Name"{...css(props.styles.inputName)} onChange={(e) => handleUserSettingsChanged('displayName', e.target.value) } value={userSettings.displayName || ''} />
          <label {...css(props.styles.nameLabel)}>and choose a spirit animal</label>
          <AvatarPicker handleUserSettingsChanged={handleUserSettingsChanged} userSettings={userSettings} currentPhoto={userSettings.photoURL} />
          <Login disabled={!userSettings.displayName.length || !userSettings.photoURL} onAuthenticate={handleAuthenticate('')} authenticating={authenticating} />
        </div>
      </div>)
  }

  if(!chat) return <div> Loading </div>

  return (
    <div  {...css(styles.app)}>
           <div {...css(styles.container)}>
             <div {...css(styles.chat)}>
               <span {...css(styles.bioShowMore)} onClick={() => {handleToggleCurrentUi('conversationBio')}}><i className={'fa fa-info-circle'} role="button"></i></span>
               <div {...css(styles.conversationWrapper, currentUi.conversationList.toggled ? styles.conversationWrapperOpen : styles.conversationWrapperClosed)}>
                 <div {...css(styles.toggler, indicateProgress && styles.indicateProgress)} role="button" onClick={() => { handleToggleCurrentUi('conversationList')}}><i className={'fa fa-users'}></i> {conversations.length - 1}</div>
                 <div {...css(styles.pwcLogoWrapper)} >
                   <img {...css(props.styles.pwcLogo)} src={logo} alt="PwC Logo"  />
                </div>
                <Conversations
                  conversations={conversations}
                  handleSetCurrentConversation={handleSetCurrentConversation}
                  currentConversationKey={currentConversationKey}
                  usersUiSettings={usersUiSettings}
                  achievements={achievements}
                  unlockedAchievements={unlockedAchievements || {}}
                  chatsWithUnread={chatsWithUnread}/>
                </div>
               <Chat
                 key={chat.key}
                 message={message}
                 messages={chat.messages || []}
                 sendMessage={handleSendMessage(chat.key)}
                 messageChanged={handleMessageChanged}
                 achievements={achievements}
                 unlockedAchievements={unlockedAchievements}
                 markAsRead={handleMarkAsRead}
                 currentUser={currentUser}
                 usersUiSettings={usersUiSettings}
                 currentReplies={currentReplies}
                 talkingTo={talkingTo}
                 handleToggleConversationBio={() => handleToggleCurrentUi('conversationBio')}
                 handleDecreaseTimer={handleDecreaseTimer}
                 currentTime={currentTime}
                  />
                <div {...css(styles.sidebar, currentUi.conversationBio.toggled && styles.bioToggled)} >
                 <div {...css(styles.bio)}>
                    <ConversationBio person={talkingTo} />
                 </div>
               </div>
             </div>
           </div>
    </div>
  )
}


const transformKeyFramesOpen = {
  '0%': {
    transform: 'translateX(-100%)',
  },

  '100%': {
    transform: 'translateX(0)',
  }
}

const transformKeyFramesClose = {
  '0%': {
    transform: 'translateX(0)',
  },

  '100%': {
    transform: 'translateX(-100%)',
  }
}

const transformKeyFramesPulse = {
  '0%': {
    transform: 'scale(1)',
  },

  '50%': {
    transform: 'scale(1.5)',
  },

  '100%': {
    transform: 'scale(1)',
  }


}

export default withStyles(({ color, unit }) => ({
  app: {

  },
  container: {

  },
  chat: {
    display: 'flex',
    height: '100vh',

    '@media (min-width: 768px)': {
      display: 'flex',
      height: '60vh',
    }
  },
  sidebar: {
    backgroundColor: 'rgba(255,255,255,0.95)',
    zIndex: '999',
    color: '#999FA3',
    display: 'none',
    '@media (min-width: 768px)': {
      display: 'block',
      borderLeft: '1px solid #E5E7E8'
    }
  },
  conversationWrapper: {
    position: 'fixed',
    zIndex: '9999',
    height: '100vh',
    background: color.secondary,
    borderRadius: '0 0 0 6px',
    display: 'flex',
    flexDirection: 'column',
    willChange: 'transform',
    transform: 'translateX(-100%)',


    '@media (min-width: 768px)': {
      position: 'relative',
      transform: 'translateX(0)',
      height: 'auto',
      zIndex: 0,
    }
  },
  conversationWrapperOpen: {
    animationName: [transformKeyFramesOpen],
    animationDuration: '1.4s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',


    '@media (min-width: 768px)': {
      animationName: 'none',
    }
  },
  conversationWrapperClosed: {
    animationName: [transformKeyFramesClose],
    animationDuration: '1.4s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',

    '@media (min-width: 768px)': {
      animationName: 'none',
    }
  },
  toggler: {
    position: 'fixed',
    right: -69,
    top: '20px',
    background: 'red',
    color: '#FFFFFF',
    padding: '5px 15px',
    background: '#E0301E',
    zIndex: 999999,
    ':hover': {
      cursor: 'pointer',
    },
    '@media (min-width: 768px)': {
      display: 'none'
    }
  },
  bioShowMore: {
    position: 'fixed',
    top: 0,
    right: 0,
    lineHeight: '72px',
    color: '#3F3F40',
    fontSize: '26px',
    float: 'right',
    zIndex: 9999,
    margin: '0 20px 0 0',
    ':hover': {
      opacity: '0.8',
      cursor: 'pointer',
    },

    '@media (min-width: 768px)': {
      display: 'none'
    },
  },
  indicateProgress: {
    animationName: [transformKeyFramesPulse],
    animationDuration: '1s',
    animationIterationCount: '4',
    animationFillMode: 'forwards',
  },
  pwcLogoWrapper: {
    background: '#d04a02',
    height: '70px',
    padding: '10px 0 10px 20px',
  },
  pwcLogo: {
    width: '62px',
  },
  closebutton: {
    float: 'right'
  },
  bio: {
    marginBottom: 'auto',
    height: '100%'
  },
  bioToggled: {
    position: "absolute",
    top: 0,
    left: 0,
    display: 'block'
  },
  login: {
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    padding: '30px',
    '@media (min-width: 768px)': {
      alignItems: 'center',
      height: '60vh',
      display: 'flex',
    }
  },
  picker: {
    flex: 2
  },
  logo: {
    '@media (min-width: 768px)': {
      flex: 1,
      borderRight: `1px solid ${color.grey}`,
      marginRight: '30px',
    }
  },
  pwcLogoColour: {
    width: 150,
    height: 150,
    marginBottom: unit * 2,
    padding: unit * 2,
  },
  inputName: {
    fontSize: '36px',
    width: '100%',
    maxWidth: '670px',
    background: 'none',
    border: '2px solid #efefef',
    textAlign: 'center',
    padding: '10px',
    margin: '0 0 40px 0',
    ':hover': {
      background: '#f9f9f9',
      cursor: 'text',
    },
    ':focus': {
        border: 'solid 2px #FFB600',
        outline: 'none',
    },
  },
  nameLabel: {
    display: 'block',
    margin: '0 0 10px 0',
    fontSize: '20px',
  }
}))(ConversationApp)

import React, { Component } from "react";
import { css, withStyles } from "../../withStyles";

import Timer from "../Timer/Timer";

class QuickReplyTimed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      answerSent: null
    };
  }

  componentDidMount() {
    const timer = this.props.handleDecreaseTimer();
    this.setState({ timer: timer });
  }

  componentWillUnmount() {
    if (!this.state.answerSent && this.props.currentTime !== null) {
      this.handleSendMessage("...");
    }
  }

  render() {
    const {
      styles,
      currentReplies,
      sendMessage,
      currentTime,
      handleDecreaseTimer
    } = this.props;
    if (!currentReplies.replies) return null;
    return (
      <div {...css(styles.list)}>
        {currentTime ? (
          <Timer
            decreaseTimer={handleDecreaseTimer}
            currentTime={currentTime}
          />
        ) : null}
        {currentReplies.replies.map(replyText => {
          return (
            <div
              {...css(styles.reply)}
              onClick={() => {
                this.handleSendMessageByClick(replyText);
              }}
              tabIndex="0"
            >
              {" "}
              {replyText}{" "}
            </div>
          );
        })}
      </div>
    );
  }

  handleSendMessage = replyText => {
    const { sendMessage } = this.props;
    this.setState({ answerSent: true });
    clearInterval(this.state.timer);
    sendMessage(replyText);
  };

  handleSendMessageByClick = replyText => {
    const { sendMessage } = this.props;
    this.setState({ answerSent: true }, () => {
      clearInterval(this.state.timer);
      sendMessage(replyText);
    });
  };
}

export default withStyles(({ extra, color, unit }) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      flexDirection: "row"
    }
  },
  reply: {
    paddingTop: unit,
    paddingBottom: unit,
    color: "#999FA3",
    backgroundColor: "#FFF",
    border: "1px solid #E5E7E8",
    margin: unit,
    marginTop: unit * 2,
    textAlign: "center",
    "@media (min-width: 768px)": {
      width: "100%"
    },
    ":hover": {
      cursor: "pointer",
      background: "#FFF7E5",
      border: "1px solid #FFB600",
      color: "#333F48"
    }
  }
}))(QuickReplyTimed);

// @flow

import React from 'react'
import { css, withStyles } from '../../withStyles';

function QuickReply({ styles, currentReplies, sendMessage }) {
  if(!currentReplies.replies) return null
  return (
    <div {...css(styles.list)}>
      {
        currentReplies.replies.map((replyText, idx) => {
          return <button key={idx} {...css(styles.reply)} onClick={() => { sendMessage(replyText) }} tabIndex={0}> { replyText } </button>
        })
      }
    </div>
  )
}

export default withStyles(({ extra, color, unit }) => ({
  list: {
    display: 'block',
    '@media (min-width: 768px)': {
      display: 'flex',
    }
  },
  reply: {
    padding: unit,
    color: color.grey,
    fontSize: 14,
    backgroundColor: '#FFF',
    border: `1px solid ${color.grey}`,
    margin: unit,
    width: 'auto',
    textAlign: 'center',
    '@media (min-width: 768px)': {
      width: '100%',
    },

    ':hover': {
    cursor: 'pointer',
    border: `1px solid ${color.orange}`,
    color: '#333F48',
    }
  }
}))(QuickReply)

export const roles = {
  aras: {
    fullName: 'Axel',
    jobTitle: 'Risk Assurance',
    title: 'Risk Assurance',
    education: `
    BSc. Business & Economics, Stockholm School of Economics
    `,
    tags: [
      'Analytical',
      'Detail Oriented',
      'Team Player'
    ],
    backgroundStory: `
    A large part of the work I do is linked to accounting and finance. Furthermore, we always work in a project form, so management courses are also beneficial because they develop one’s ability to work in a group.

    I did not study IT, it was a new area for me when I arrived at Risk Assurance. If you have studied courses in system science, they are very helpful because they give you a big head start in many projects.
    `,
    roleText: `
    Enjoying working with other people is extremely important, it’s a bit tricky to be too strong individualist. All work we do goes into projects and builds on cooperation with others. However, the teams need different abilities, so you can always be yourself.

    Otherwise, it’s a plus if you are analytical, structured and like to laugh - because we do so much at our department.
    `,
    best: `
    As long as the job is done, I have to do a little bit what I want. There is a great freedom at work and I can focus on what I think is fun. Another invaluable part at work is my amazing colleagues. I have never experienced the atmosphere and cohesion we have in the office at another workplace. They make it easy to go to work every day, even if it would be stressful or a lot to do.
    `,
    whyPwc:  `
    Much is about the colleagues and the positive and friendly atmosphere that prevails at work. When I go to work in the morning, I know I will get myself at least one big laugh during the day. We care about each other and are good at what we do. I get challenged and always learn new things, but I do it under casual and fun forms.
    `

  },
  cyberSecurity: {
    fullName: 'Jessica',
    jobTitle: 'Cyber Security',
    education: `
    Bachelor of Science in Politics and Economics - Major: Political Science, Lunds University
    Master of Science in Political Science: Security Studies,  Försvarshögskolan
    `,
    title: 'Cyber Security',
    tags: [
      'Tech savvy',
      'Logical Reasoning',
      'Team Player'
    ],
    backgroundStory: `My background in Political Science has really been a help to me in my every-day work. I might not get to apply theories of Internal Relations very often, but my education has helped me develop my analytical thinking and ability to quickly understand various situations which is very valuable when working in this versatile environment with different projects.`,
    roleText: `I would say being able to adapt to any situation has been very useful. In addition to that I think being an outgoing and social person has helped me a lot. Last but not least being able take the initiative when needed has proven to be very useful in my position.`,
    best: `I love that the work is very varied, there are so many different aspects of cyber security and I look very much forward to learning more about the different areas.`,
    whyPwc:  `I have awesome colleagues, and I get to work with a lot of different people with different competencies so I constantly grow and get inspired. On top of that we get the best “fika” in Stockholm on Fridays`

  },
  tax: {
    fullName: 'Darko',
    jobTitle: 'Tax Consultant',
    education: `Master of Laws, School of Business, Economics and Law at the University of Gothenburg`,
    title: 'Taxes',
    tags: [
      'Detail oriented', 
      'High-tempo',
      'Team work'
    ],
    backgroundStory: `In-depth courses in Tax`,
    roleText: `Curiosity`,
    best: `Intensity and Challenges`,
    whyPwc:  `Good colleagues and fun work`
  },
  deals: {
    fullName: 'Johanna',
    jobTitle: 'Senior Associate within the Valuation & Analysis team',
    education: `
    Elite MSc in Advanced Finance and Economics, Copenhagen Business School.
    Bachelor’s degree Business and Economics, Uppsala University
    `,
    title: 'Deals',
    tags: [
      'Analytical',
      'Flexible', 
      'Communicative'
    ],
    backgroundStory: `I have benefited from a course I read in corporate evaluation. However, I learned the most of the subject I have learned from PwC`,
    roleText: `Being social and curious has helped me a lot when I was new to the workplace. It is also important not to be afraid to ask questions to quickly absorb all the colleagues’ knowledge and experience.`,
    best: `The best thing about the job is that the tasks are fun, developing and varying. There is a lot of expertise within the company and it creates a stimulating environment.`,
    whyPwc:  `The main factor that makes me feel so comfortable at PwC are all my nice, ambitious and helpful colleagues.`
  },
  /*consulting: {
    fullName: 'Axel Lastname',
    jobTitle: 'IT Consultant',
    education: `University name here
    B.A. Course title here
    (2014-2017)`,
    title: 'Consulting',
    tags: [
      'Tech savvy',
      'Logical Reasoning',
      'Risk Mitigation'
    ],
    backgroundStory: `Joined PwC 7 months ago. Was hired
    while studying his last year in university and joined
    straight after graduating. His brother does the same role
    in a competitor company and thought it would suit him
    also.`,
    whyPwc:  `I feel that there is a very positive culture at PwC, people
    are kind and help each other out.Employees are
    encouraged to grow and you get a lot of support, also
    its okay to make mistakes and you are able to learn from
    them.`
  },*/
  publicSector: {
    fullName: 'Erik',
    jobTitle: 'Public Sector',
    education: `
    Degree of Bachelor of Science in Politics and Economics, Luleå Tekniska Universitet.`,
    title: 'Public Sector',
    tags: [
      'Democratisation',  
      'Efficiency',
      'Public Interest'
    ],
    backgroundStory: `Courses in political and public law have been key courses for understanding the political organization in Sweden as well as the laws, regulations and cultures that govern our public governance.`,
    roleText: `My primary asset is that I’m good at organizing, systematizing and analyzing data. Because we meet many people, as well far out in civil servants’ organizations as political rulers, a social ability to meet people on the basis of their prerequisites has been crucial for managing the job.`,
    best: `That what I do actually contributes to the progressive development of public Sweden for the better. Even we have a very independent and free job where we largely plan our working day.`,
    whyPwc:  `Because I get an opportunity to work with so many different issues in so many different organizations in a way that I would not get with any other employer today.`
  }
}

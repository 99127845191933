// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase'

import axios from 'axios'

import messagesFeature from '../features/messages'
import usersFeature from '../features/users'
import achievementsFeature from '../features/achievements'
import recordsFeature from '../features/records'
import conversationsFeature from '../features/conversations'
import uiFeature from '../features/ui'
import ReactGA from "react-ga";
import foldPayload from '../lib/foldPayload';
import { jsonToStructProto } from '../lib/structJson';

import AppView from './AppView'

import { css, withStyles } from '../withStyles'

import type { User } from '../types'
import type { UserSettings } from '../features/users/types'

import emperor from './AvatarPicker/avatars/starwars-the_emperor.png'
import darth from './AvatarPicker/avatars/starwars-darth_maul.png'
import vader from './AvatarPicker/avatars/starwars-darth-vader.png'
import royalguard from './AvatarPicker/avatars/starwars-royal_guard.png'

import jessica from './AvatarPicker/avatars/team/transparent/jessica.png'
import johanna from './AvatarPicker/avatars/team/transparent/johanna.png'
import erik from './AvatarPicker/avatars/team/transparent/erik.png'
import axel from './AvatarPicker/avatars/team/transparent/axel.png'
import darko from './AvatarPicker/avatars/team/transparent/darko.png'
import jane from './AvatarPicker/avatars/team/transparent/jane.png'
import fredrik from './AvatarPicker/avatars/team/transparent/fredrik.png'


const contextActions = [
  'puzzle.cryptogram.triggered',
  'puzzle.aras.triggered',
  'puzzle.consulting.triggered',
  'puzzle.tax.triggered'
]

const eventActions = [
  'EVENT_1',
  'EVENT_2',
  'EVENT_PUZZLE'
]

const skippableActions = [
  'puzzle.aras.triggered',
  'puzzle.cryptogram.triggered',
  'puzzle.consulting.triggered',
  'puzzle.tax.triggered'
]

type Props = {
  messagesLoaded: Function,
  oldAchievementLoaded: Function,
  newAchievementLoaded: Function,
  oldMessageLoaded: Function,
  newMessageLoaded: Function,
  recordUserRead: Function,
  recordsLoaded: Function,
  userAuthenticated: Function,
  userUnauthenticated: Function,
  userInfoLoaded: Function,
  unlockedAchievements: Function,
  unlockedAchievementsLoaded: Function,
  messageChanged: Function,
  clearMessage: Function,
  setCurrentConversation: Function,
  conversationLoaded: Function,
  userProfileUpdated: Function,
  usernameSettingChanged: Function,
  uiComponentMoved: Function,
  quickRepliesLoaded: Function,
  resultsLoaded: Function,
  currentUser: User,
  currentUserId: string,
  records: { messages: Array<string>, achievements: Array<string> },
  messages: Array<string>,
  chats: Array<{}>,
  achievements: Array<{}>,
  message: string,
  userChats: Array<{}>,
  currentConversationKey: string,
  userSettings: UserSettings,
  usersUiSettings: {}
}

type State = {
  messageQueue: Array<messagesFeature.types.Message>
}

class AppController extends Component<Props, State> {
  debouncedMessage: Function

  constructor(props) {
    super(props)
    this.state = {
      messageQueue: [],
      passwordSequence: {},
      enteredPassword: false,
      authenticating: false,
      indicateProgress: false,
      unlocks: 0
    }

    this.debouncedMessage = this.debounce(this.handleNewMessages, 200)
    window.requestResults = this.handleRequestResults.bind(this)
  }
  componentDidMount() {
    const {
      messagesLoaded,
      oldAchievementLoaded,
      newAchievementLoaded,
      oldMessageLoaded,
      newMessageLoaded,
      recordUserRead,
      recordsLoaded,
      userAuthenticated,
      userInfoLoaded,
      unlockedAchievementsLoaded,
      setCurrentConversation,
      conversationLoaded,
      userProfileUpdated,
      conversations,
      currentConversationKey,
      unlockedAchievements
    } = this.props

    window.addEventListener('keyup', (e) => {
      const { currentConversationKey, conversations, unlockedAchievements } = this.props
      const currentIndex = conversations.findIndex((conv) => {
        return conv.key === currentConversationKey
      })
      if (e.keyCode === 40) {
        if (currentIndex + 1 > conversations.length - 1) return
        if (!Object.keys(unlockedAchievements).includes(conversations[currentIndex + 1].accessKey)) return
        setCurrentConversation(conversations[currentIndex + 1].key)
      } else if (e.keyCode === 38) {
        if (currentIndex - 1 < 0) return
        if (!Object.keys(unlockedAchievements).includes(conversations[currentIndex - 1].accessKey)) return
        setCurrentConversation(conversations[currentIndex - 1].key)
      }
    })

    const sequences = {
      "1": [1, 4, 7, 10, 13, 16],
      "2": [3, 8, 13, 18, 23, 28]
    }

    const id = Math.floor(Math.random() * 2) + 1
    const sequence = sequences[`${id}`]
    const manipulatedSequence = sequence.map((val, index) => {
      if (index === 1 || index == 4) return "_"
      return val
    })

    this.setState({
      passwordSequence: {
        correctSequence: sequence,
        testSequence: manipulatedSequence
      }
    })

    this.handleUnauthenticate()
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!user.displayName) {
          const { userSettings } = this.props
          usersFeature.service.updateUserProfile(userSettings).then(() => {
            userInfoLoaded({ uid: user.uid, displayName: userSettings.displayName })
          })
        }
        if (!user.photoURL) {
          const { userSettings } = this.props
          usersFeature.service.updateUserProfile(userSettings).then(() => {
            userInfoLoaded({ uid: user.uid, photoURL: userSettings.photoURL })
          })
        }
        userAuthenticated({ uid: user.uid, displayName: user.displayName, photoURL: user.photoURL })
        firebase.database().ref(`users/${user.uid}`).on('value', this.runSetup)
      }
    }
    )
  }

  render() {
    const {
      chats,
      message,
      achievements,
      unlockedAchievements,
      currentUser,
      currentConversationKey,
      setCurrentConversation,
      conversations,
      recordUserRead,
      userSettingsChanged,
      userSettings,
      usersUiSettings,
      toggleCurrentUi,
      currentUi,
      moveUiComponent,
      currentReplies,
      userResults,
      decreaseTimer,
      currentTime,
      chatsWithUnreadMessages,
    } = this.props

    return (
      <AppView
        chats={chats}
        message={message}
        achievements={achievements}
        unlockedAchievements={unlockedAchievements}
        conversations={conversations}
        currentUser={currentUser}
        currentConversationKey={currentConversationKey}
        handleAuthenticate={this.handleAuthenticate}
        handleUnauthenticate={this.handleUnauthenticate}
        handleSendMessage={this.handleSendMessage}
        handleSetCurrentConversation={(conversationId) => {
          toggleCurrentUi('conversationList')
          setCurrentConversation(conversationId)
        }}
        handleMessageChanged={this.handleMessageChanged}
        handleMarkAsRead={recordUserRead}
        handleUserSettingsChanged={userSettingsChanged}
        handleToggleCurrentUi={toggleCurrentUi}
        handleMoveUiComponent={moveUiComponent}
        handleDecreaseTimer={this.handleDecreaseTimer}
        userSettings={userSettings}
        usersUiSettings={usersUiSettings}
        currentUi={currentUi}
        currentReplies={currentReplies}
        userResults={userResults}
        currentTime={currentTime}
        started={this.state.started}
        passwordSequence={this.state.passwordSequence}
        enteredPassword={this.state.enteredPassword}
        indicateProgress={this.state.indicateProgress}
        handleUserEnteredPassword={this.handleUserEnteredPassword}
        handleUserStart={this.handleUserStart}
        authenticating={this.state.authenticating}
        chatsWithUnread={chatsWithUnreadMessages}
      />
    )
  }

  handleUserStart = () => {
    this.setState({ started: true })
  }

  handleUserEnteredPassword = () => {
    this.setState({ enteredPassword: true })
  }

  runSetup = (snapshot) => {
    const {
      setCurrentConversation,
      recordsLoaded,
      userInfoLoaded
    } = this.props


    const snapshotData = snapshot.val()
    if (!snapshotData) return
    userInfoLoaded(snapshotData)
    setCurrentConversation(snapshotData.initialChatKey)
    const { chats } = snapshotData
    Object.keys(chats).forEach((key) => {
      firebase.database().ref(`records/${key}`).once('value', (d) => {
        recordsLoaded(key, d.val())
        this.setMessageListener(key)
        this.setAchievementListener(key)
        this.setRepliesListener(key)
      })
      this.runBotSetupForConversation(key)
    })
    this.setProgressListener()
    userInfoLoaded({ uid: 'royalguard', displayName: 'Royal Guard', photoURL: royalguard })
  }

  saveReplies = (key: string, replies) => {
    firebase.database().ref(`replies/${key}`).set({
      currentReplies: replies
    })
  }

  clearReplies = (key: string) => {
    firebase.database().ref(`replies/${key}`).set({
      currentReplies: []
    })
  }

  setRepliesListener = (key: string) => {
    const { quickRepliesLoaded } = this.props

    firebase.database().ref(`replies/${key}`).on('value', (data) => {
      if (!data.val()) return
      quickRepliesLoaded(key, data.val().currentReplies)
    })
  }

  setMessageListener = (key: string) => {
    const { newMessageLoaded, oldMessageLoaded } = this.props

    firebase.database().ref(`messages/${key}`).on('child_added', (data) => {
      const records = this.props.records[key]
      const message = data.val()

      if (records && !records.messages[data.key] && !message.skipAnimation) {
        firebase.database().ref(`records/${key}/messages/${data.key}`).set(true)
        this.setMessageQueue({
          conversationKey: key,
          message: message,
          messageKey: data.key
        })
        if (!data.val().isUser) {
          this.debouncedMessage()
        } else {
          newMessageLoaded(key, data.val(), data.key)
        }
      } else {
        if (message.payload && (
          message.payload.type === 'quick_reply' ||
          message.payload.type === 'quick_reply_colored' ||
          message.payload.type === 'quick_reply_timed'
        )
        ) {
          return
        }
        oldMessageLoaded(key, data.val(), data.key)
      }
    })
  }

  setAchievementListener = (key: string) => {
    const { newAchievementLoaded, oldAchievementLoaded, currentUser } = this.props

    firebase.database().ref(`achievements/${currentUser.uid}`).on('child_added', (data) => {
      const queueCheckInterval = setInterval(() => {
        if (this.state.messageQueue.length < 2) {
          clearInterval(queueCheckInterval)
          this.setState({ unlocks: this.state.unlocks + 1 })
          const records = this.props.records[key]
          if (records && !records.achievements[data.key]) {
            firebase.database().ref(`records/${key}/achievements/${data.key}`).set(true)
            newAchievementLoaded(currentUser.uid, data.val(), data.key)
          } else {
            oldAchievementLoaded(currentUser.uid, data.val(), data.key)
          }
        }
      }, this.state.unlocks === 0 ? 0 : 5000)
    })
  }

  setProgressListener = () => {
    const { currentUser } = this.props
    firebase.database().ref(`progress/${currentUser.uid}`).on('value', (data) => {
      if (data.val() > 0) this.triggerProgressIndication()
      if (data.val() > 5) setTimeout(this.triggerEnding, 14000)
    })
  }

  triggerProgressIndication = () => {
    this.setState({ indicateProgress: true })
    setTimeout(() => {
      this.setState({ indicateProgress: false })
    }, 4000)
  }

  triggerEnding = () => {
    const { initialChatKey } = this.props.currentUser
    const currentUserId = this.props.currentUser.uid
    this.props.setCurrentConversation(initialChatKey)
    const fredKey = '9e69d691cb754c66940c44862e213a08'
    const messages = [{ conversationKey: initialChatKey, message: { message: 'Thanks for doing a great job today!', senderId: '9e69d691cb754c66940c44862e213a08' }, messageKey: 'å' },
    { conversationKey: initialChatKey, message: { message: 'We did a little evaluation based on your answers.', senderId: '9e69d691cb754c66940c44862e213a08' }, messageKey: 'ä' },
    { conversationKey: initialChatKey, message: { message: 'Here are your results!', senderId: '9e69d691cb754c66940c44862e213a08' }, messageKey: 'ö' }]

    messages.forEach(message => {
      this.setMessageQueue({
        conversationKey: message.conversationKey,
        message: message.message,
        messageKey: message.messageKey
      })
      this.debouncedMessage()
    })

    setTimeout(this.handleRequestResults, 11000)
  }

  runBotSetupForConversation = (key: string) => {
    const { conversationLoaded, userInfoLoaded } = this.props

    firebase.database().ref(`chats/${key}`).once('value').then((data) => {
      const avatars = {
        "148251fc4907490f8eac2e0f339532a4": axel,
        "9e69d691cb754c66940c44862e213a08": fredrik,
        "bb083b4f020c4390bc4934585d674496": jessica,
        "a6cd4883790d4a2f8132e97ccd2e4916": erik,
        "458d800ab0934e07a9ec16f3e1b3bc16": johanna,
        "2a66e207e9c64242b2767d428fdadf4d": darko,
        "10b934249a2047d3bd862a39806640fd": vader
      }
      const conversationInfo = data.val()
      conversationLoaded(key, conversationInfo)
      userInfoLoaded({ uid: conversationInfo.accessKey, displayName: conversationInfo.title, photoURL: avatars[conversationInfo.accessKey], bio: conversationInfo.bio })
    })
  }

  setMessageQueue = (message: messagesFeature.types.Message): void => {
    const { messageQueue } = this.state
    this.setState({
      messageQueue: [...messageQueue, message]
    })
  }

  handleNewMessages = (): void => {
    const { newMessageLoaded, quickRepliesLoaded, currentConversationKey, setTimer } = this.props
    const { messageQueue } = this.state

    if (messageQueue.length > 1) {
      messageQueue.forEach((message, index) => {
        setTimeout(() => {
          const foldedPayload = foldPayload(message.message.payload)
          if (foldedPayload) {
            switch (foldedPayload.type) {
              case 'quick_reply': {
                this.saveReplies(message.conversationKey, foldedPayload.value)
                quickRepliesLoaded(message.conversationKey, foldedPayload.value)
                return
              }
              case 'quick_reply_timed': {
                quickRepliesLoaded(message.conversationKey, foldedPayload.value, 'timed')
                setTimer(message.conversationKey)
                return
              }
              case 'quick_reply_colored': {
                quickRepliesLoaded(message.conversationKey, foldedPayload.value, 'color')
                setTimer(message.conversationKey)
                return
              }
              default: {
                const messageWithFoldedPayload = {
                  ...message.message,
                  payload: foldPayload(message.message.payload)
                }
                newMessageLoaded(message.conversationKey, messageWithFoldedPayload, message.messageKey)
                return
              }
            }
          }
          newMessageLoaded(message.conversationKey, message.message, message.messageKey)
        }, index * 3000)
      })
    } else {
      const message = messageQueue[0]
      newMessageLoaded(message.conversationKey, message.message, message.messageKey)
    }

    this.setState({
      messageQueue: []
    })
  }

  handleRequestResults = () => {
    const { currentUser, resultsLoaded } = this.props
    ReactGA.event({
      category: 'User',
      action: 'User finisihed the experience'
    })
    firebase.database().ref(`results/${currentUser.uid}`).on('value', (data) => {
      resultsLoaded(currentUser.uid, data.val())
    })
  }

  handleAuthenticate = (provider) => {
    return () => {
      this.setState({ authenticating: true })
      usersFeature.service.authenticateUser(provider).then(() => {
        console.log('auth complete')
        ReactGA.event({
          category: 'User',
          action: 'User started experience'
        })
      })
    }
  }

  handleUnauthenticate = () => {
    const { userUnauthenticated } = this.props
    return usersFeature.service.unAuthenticateUser().then(() => {
      userUnauthenticated()
    })
  }

  handleMessageChanged = (event) => {
    const { messageChanged } = this.props
    const message = event.target.value
    messageChanged(message)
  }

  handleDecreaseTimer = () => {
    const { currentConversationKey } = this.props
    let timer = setInterval(() => {
      if (this.props.getCurrentTime(currentConversationKey) <= 0) {
        this.handleSendMessage(currentConversationKey)("...")
        clearInterval(timer)
        return
      }
      this.props.decreaseTimer(currentConversationKey)
    }, 1000)
    return timer
  }

  handleSendMessage = (chatId, eventName) => {
    //this.clearReplies(chatId)
    const { clearMessage, currentUserId, currentUser, setCurrentContext, currentContext } = this.props

    return (messageFromAction) => {
      const accessToken = currentUser.chats[chatId]
      const message = this.props.message || messageFromAction
      const contexts = [
        {
          name: 'keys',
          lifespanCount: 2
        }
      ]

      currentContext && contexts.push(currentContext)

      const requestBase = {
        queryParams: {
          payload: jsonToStructProto({
            'userid': currentUser.uid,
            'accessKey': currentUser.chats[chatId]
          }),
          contexts: contexts
        }
      }

      if (message) {
        firebase.database().ref(`messages/${chatId}`).push({
          message: message,
          isUser: true,
          senderId: currentUser.uid
        })
        clearMessage(chatId)

        const textRequest = {
          ...requestBase,
          queryInput: {
            text: {
              text: message,
              languageCode: 'en-US'
            }
          }
        }

        axios.post(process.env.REACT_APP_DIALOGFLOW_URL, { chatId, accessToken, dfRequest: textRequest })
          .then(response => {
            let data = response.data[0]

            if (contextActions.includes(data.queryResult.action)) {
              setCurrentContext(chatId, { name: 'puzzle-answer', lifespanCount: 5 })
            }
            
            if (eventActions.includes(data.queryResult.action)) {
              const eventRequest = {
                ...requestBase,
                queryInput: {
                  event: {
                    name: data.queryResult.action,
                    parameters: {},
                    languageCode: 'en-US'
                  }
                }
              }

              axios.post(process.env.REACT_APP_DIALOGFLOW_URL, { chatId, accessToken, dfRequest: eventRequest })
                .then((response2) => {
                  let data2 = response2.data[0]

                  if (skippableActions.includes(data2.queryResult.action)) return
                  [...(data.queryResult.fulfillmentMessages || []), ...(data2.queryResult.fulfillmentMessages || [])].forEach((message, index) => {
                    firebase.database().ref(`messages/${chatId}`).push({
                      ...message,
                      message: message.text ? message.text.text.join("") : '',
                      senderId: currentUser.chats[chatId]
                    })
                  })
                })
            }
            else if (!skippableActions.includes(data.queryResult.action)) {
              data.queryResult.fulfillmentMessages.forEach((message, index) => {
                firebase.database().ref(`messages/${chatId}`).push({
                  ...message,
                  message: message.text ? message.text.text.join("") : '',
                  senderId: currentUser.chats[chatId]
                })
              })
            }
          })
          .catch((error) => { console.error(error) })
      } else {
        const eventRequest = {
          ...requestBase,
          queryInput: {
            event: {
              name: eventName,
              parameters: {},
              languageCode: 'en-US'
            }
          }
        }

        axios.post(process.env.REACT_APP_DIALOGFLOW_URL, { chatId, accessToken, dfRequest: eventRequest })
          .then(response => {
            response.result.fulfillmentMessages.forEach((message) => {
              firebase.database().ref(`messages/${chatId}`).push({
                ...message,
                senderId: currentUser.chats[chatId],
                message: message.text ? message.text.text.join("") : ''
              })
            })
          })
      }
    }
  }

  debounce = (func, wait, immediate) => {
    let timeout
    return () => {
      const context = this, args = [func, wait, immediate];
      const later = () => {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) {
        func.apply(context, args)
      }
    }
  }
}

function mapStateToProps(state, props) {
  const { currentUserId } = state.users
  const userChats = usersFeature.selectors.getChats(state, currentUserId)
  const conversations = userChats ? Object.keys(userChats).map((conversationKey) => {
    return conversationsFeature.selectors.getConversation(state, conversationKey)
  }) : []
  const currentConversationKey = messagesFeature.selectors.getCurrentConversationKey(state)


  return {
    userChats: userChats,
    conversations: conversations,
    message: messagesFeature.selectors.getCurrentMessage(state),
    chats: messagesFeature.selectors.getMessagesForChats(state, userChats),
    currentUser: usersFeature.selectors.getCurrentUser(state, currentUserId),
    achievements: achievementsFeature.selectors.getAvailableAchievements(state),
    records: userChats ? recordsFeature.selectors.getRecords(state) : {},
    unlockedAchievements: userChats ? achievementsFeature.selectors.getUnlockedAchievements(state, currentUserId) : [],
    currentConversationKey: messagesFeature.selectors.getCurrentConversationKey(state),
    userSettings: usersFeature.selectors.getSettings(state),
    usersUiSettings: usersFeature.selectors.getUiSettings(state),
    currentUi: uiFeature.selectors.getCurrentUi(state),
    currentReplies: uiFeature.selectors.getCurrentReplies(state, currentConversationKey),
    currentContext: conversationsFeature.selectors.getCurrentContext(state, currentConversationKey),
    getCurrentTime: (conversationKey) => {
      return uiFeature.selectors.getCurrentTimer(state, conversationKey)
    },
    currentTime: uiFeature.selectors.getCurrentTimer(state, currentConversationKey),
    userResults: usersFeature.selectors.getUserResults(state, currentUserId),
    chatsWithUnreadMessages: messagesFeature.selectors.hasUnreadForChat(state, currentUserId)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userAuthenticated: (user: User) => {
      dispatch(usersFeature.actions.userAuthenticated({ user }))
    },
    userUnauthenticated: () => {
      dispatch(usersFeature.actions.userUnauthenticated())
    },
    userInfoLoaded: (user: User) => {
      dispatch(usersFeature.actions.userInfoLoaded({ user }))
    },
    messagesLoaded: (conversationKey: string, messages: Array<messagesFeature.types.Message>) => {
      dispatch(messagesFeature.actions.messagesLoaded({ conversationKey, messages }))
    },
    oldAchievementLoaded: (conversationKey: string, achievement, achievementKey: string) => {
      dispatch(achievementsFeature.actions.oldAchievementLoaded({ conversationKey, achievement, achievementKey }))
    },
    newAchievementLoaded: (conversationKey: string, achievement, achievementKey: string) => {
      dispatch(achievementsFeature.actions.newAchievementLoaded({ conversationKey, achievement, achievementKey }))
    },
    oldMessageLoaded: (conversationKey: string, message: messagesFeature.types.Message, messageKey: string) => {
      dispatch(messagesFeature.actions.oldMessageLoaded({ conversationKey, message, messageKey }))
    },
    newMessageLoaded: (conversationKey: string, message: messagesFeature.types.Message, messageKey: string) => {
      dispatch(messagesFeature.actions.newMessageLoaded({ conversationKey, message, messageKey }))
    },
    messageChanged: (message: string) => {
      dispatch(messagesFeature.actions.messageChanged(message))
    },
    clearMessage: (chatId: string) => {
      dispatch(messagesFeature.actions.messageCleared({ chatId }))
    },
    unlockedAchievementsLoaded: (conversationKey: string, achievements) => {
      dispatch(achievementsFeature.actions.unlockedAchievementsLoaded({ conversationKey, achievements }))
    },
    recordUserRead: (conversationKey: string, recordType: string, recordKey: string) => {
      dispatch(recordsFeature.actions.recordUserRead({ conversationKey, recordType, recordKey }))
    },
    recordsLoaded: (conversationKey: string, records) => {
      dispatch(recordsFeature.actions.recordsLoaded({ conversationKey, records }))
    },
    setCurrentConversation: (conversationKey: string) => {
      dispatch(messagesFeature.actions.setCurrentConversation(conversationKey))
    },
    conversationLoaded: (conversationKey: string, conversation) => {
      dispatch(conversationsFeature.actions.conversationLoaded({ conversationKey, conversation }))
    },
    userSettingsChanged: (settingKey: string, value: any) => {
      const settings = {
        [settingKey]: value
      }
      dispatch(usersFeature.actions.userSettingsChanged({ settings }))
    },
    userProfileUpdated: (settings: Settings, userId: string) => {
      dispatch(usersFeature.actions.userProfileUpdated({ settings, userId }))
    },
    toggleCurrentUi: (uiKey: string) => {
      dispatch(uiFeature.actions.toggleCurrentUi({ uiKey }))
    },
    moveUiComponent: (uiKey: string) => {
      return (x, y) => {
        dispatch(uiFeature.actions.moveUiComponent({ uiKey, x, y }))
      }
    },
    quickRepliesLoaded: (conversationKey: string, replies: Array<string>, modifier: string) => {
      dispatch(uiFeature.actions.quickRepliesLoaded({ conversationKey, replies, modifier }))
    },
    setCurrentContext: (conversationKey: string, context: {}) => {
      dispatch(conversationsFeature.actions.conversationContextSet({ context, conversationKey }))
    },
    resultsLoaded: (userId: string, results: {}) => {
      dispatch(usersFeature.actions.resultsLoaded({ userId, results }))
    },
    setTimer: (conversationKey: string) => {
      dispatch(uiFeature.actions.timerSet({ conversationKey }))
    },
    decreaseTimer: (conversationKey: string) => {
      dispatch(uiFeature.actions.timerDecrease({ conversationKey }))
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(({ color, unit }) => ({
}))(AppController))

// @flow

export const CONVERSATION_CONTEXT_SET:  string = 'CONVERSATION_CONTEXT_SET'
export const CONVERSATION_LOADED: string = 'CONVERSATION_LOADED'
export const NEW_ACHIEVEMENT_LOADED: string = 'NEW_ACHIEVEMENT_LOADED'
export const OLD_ACHIEVEMENT_LOADED: string = 'OLD_ACHIEVEMENT_LOADED'
export const ACHIEVEMENT_UNLOCKED: string = 'ACHIEVEMENT_UNLOCKED'
export const MESSAGE_CHANGED: string = 'MESSAGE_CHANGED'
export const MESSAGE_CLEARED: string = 'MESSAGE_CLEARED'
export const MESSAGE_SENT: string = 'MESSAGE_SENT'
export const OLD_MESSAGE_LOADED: string = 'OLD_MESSAGE_LOADED'
export const NEW_MESSAGE_LOADED: string = 'NEW_MESSAGE_LOADED'
export const MESSAGES_LOADED: string = 'MESSAGES_LOADED'
export const MEMBERS_FOR_CONVERSATION_LOADED: string = 'MEMBERS_FOR_CONVERSATION_LOADED'
export const RECORD_USER_READ: string = 'RECORD_USER_READ'
export const RECORDS_MESSAGE_NOTED: string = 'RECORDS_MESSAGE_NOTED'
export const RECORDS_ACHIEVEMENT_NOTED: string = 'RECORDS_ACHIEVEMENT_NOTED'
export const RECORDS_LOADED: string = 'RECORDS_LOADED'
export const RESULTS_LOADED: string = 'RESULTS_LOADED'
export const SET_CURRENT_CONVERSATION: string = 'SET_CURRENT_CONVERSATION'
export const SET_CURRENT_UI: string = 'SET_CURRENT_UI'
export const UNLOCKED_ACHIEVEMENTS_LOADED: string = 'UNLOCKED_ACHIEVEMENTS_LOADED'
export const USER_AUTHENTICATION_SUCCESS: string = 'USER_AUTHENTICATION_SUCCESS'
export const USER_UNAUTHENTICATION_SUCCESS: string = 'USER_UNAUTHENTICATION_SUCCESS'
export const USER_INFO_LOADED: string = 'USER_INFO_LOADED'
export const USER_SETTINGS_UPDATED:  string = 'USER_SETTINGS_UPDATED'
export const USER_SETTINGS_CHANGED: string = 'USER_SETTINGS_CHANGED'
export const USER_PROGRESS_UPDATED: string = 'USER_PROGRESS_UPDATED'
export const UI_COMPONENT_MOVED: string = 'UI_COMPONENT_MOVED'
export const QUICK_REPLIES_LOADED: string = 'QUICK_REPLIES_LOADED'
export const TIMER_SET: string = 'TIMER_SET'
export const TIMER_DECREASE: string = 'TIMER_DECREASE'

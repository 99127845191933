import React, { Component } from 'react'
import { css, withStyles } from '../../withStyles';
import desktopbg from '../../images/pwc-desktop.jpg'


class LoginPuzzle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tries: 0
    }
  }

  render() {
    const { styles, message, password, handleUserEnteredPassword } = this.props
    const { tries, errorBounce, errorFade } = this.state
    return (
      <form {...css(styles.loginPuzzle)} onSubmit={this.checkLoginPuzzle}>
        { /* <span>Password:</span> */ }
        <div {...css(styles.inputContainer)}>
          { password.testSequence && password.testSequence.map((val, idx) => {
              return (
                <div key={idx} {...css(styles.puzzleItem, errorBounce && styles.errorBounce )}>
                  { typeof val === 'number' ? <input type="text" {...css(styles.input, )} disabled value={val} />  : <input {...css(styles.input, styles.clickableInput)} placeholder="?"  type="text" tabIndex="0"/> }
                </div>
              )
            })
          }
        </div>
        <div {...css(styles.tryAgain, errorFade && styles.errorFade )}>
          <strong>Not quite right... try again</strong>
        </div>
        <button {...css(styles.loginButton)} type="submit">Enter</button>
        {
          tries >= 3 ? (
            <button {...css(styles.loginSubtitle)}
              type="button"
              onClick={handleUserEnteredPassword}
              tabIndex="0">
              Forget the puzzle, just let me in!
            </button> ) : <p {...css(styles.loginSubtitle)}> (Hint : Solve and complete the sequence above to login) </p>

        }
      </form>
    )
  }

  checkLoginPuzzle = (event) => {
    event.preventDefault()
    const { handleUserEnteredPassword, password } = this.props
    const currentSequence = [].slice.call(document.querySelectorAll('input')).map((node) => {
      return node.value
    })
    if(currentSequence.join('') === password.correctSequence.join('')) {
      handleUserEnteredPassword()
    } else {
      const inputs = document.querySelectorAll('input:enabled')
      for(let i = 0; i < inputs.length; i++) {
        inputs[i].value = ''
      }
      this.setState({ tries: this.state.tries + 1 })
      this.setState({ errorBounce: true }, () => {
        setTimeout(() => {
          this.setState({ errorBounce: false })
        }, 500);
      })
      this.setState({ errorFade: true }, () => {
        setTimeout(() => {
          this.setState({ errorFade: false })
        }, 3000);
      })
    }
  }
}

const bounceKeyframes = {
  '0%': {
		transform: 'initial',
	},

  '15%': {
    transform: 'translateX(-35px)'
  },

  '30%': {
    transform: 'translateX(25px)'
  },

  '45%': {
    transform: 'translateX(-10px)'
  },

  '60%': {
		transform: 'translateX(10px)'
	},

  '75%': {
    transform: 'translateX(-5px)'
  },

  '90%': {
		transform: 'translateX(5px)'
	},

  '100%': {
		transform: 'initial'
	}
}

const fadeKeyframes = {
  '0%': {
		opacity: '1',
	},

  '20%': {
    opacity: '1',
  },

  '100%': {
    opacity: '0'
	}
}

export default withStyles(({ extra, color, unit }) => ({
  loginPuzzle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left'
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    padding: '30px 30px 0 30px',
  },
  puzzleItem: {
    fontSize: unit * 3,
    display: 'block',
    background: 'none',
    border: 0,
    padding: '0px 12px 0 12px',
    '@media (max-width: 720px)': {
        padding: '7px 7px 0 7px',
       }
  },
  input: {
    fontSize: unit * 3,
    width: '100%',
    height: '100%',
    background: 'white',
    textAlign: 'center',
    padding: '10px',
    '@media (max-width: 800px)': {
      fontSize: '24px',
      padding: '5px',
    },
  },
  clickableInput: {
    opacity: 0.5,
    transition: 'opacity 0.4s ease',
    ':focus': {
      opacity: 1,
      border: '2px solid #FFB600',
      background: 'white',
      outline: 'none',
    },
    ':hover': {
      opacity: 1,
      background: color.lightGrey,
      background: 'white',
      cursor: 'text',
    },
  },
  loginButton: {
    fontSize: unit * 2,
    backgroundColor: color.orange,
    border: 'none',
    padding: '10px 35px',
    color: '#FFF',
    ':focus': {
      outline: 'none',
    },
    ':hover': {
      backgroundColor: color.grey,
      cursor: 'pointer',
    }
  },
  loginButtonForgot: {
    fontSize: '20px',
    fontStyle: 'italic',
    background: 'none',
    border: 'none',
    padding: '30px 30px 0 30px',
    position: 'absolute',
    bottom: '-60px',
    ':hover': {
      color: '#E0301E',
      cursor: 'pointer',
    },
  },
  loginSubtitle: {
    fontSize: '20px',
    fontStyle: 'italic',
    color: 'white',
    padding: '30px 30px 0 30px',
    background: 'none',
    border: 'none',
    '@media (max-width: 450px)': {
      fontSize: '16px',
    },
  },
  tryAgain: {
    opacity: '0',
    padding: '10px 0 25px 0',
    fontSize: '18px',
    fontWeight: 'normal!important',
  },
  errorBounce: {
    animationName: [bounceKeyframes],
    animationDuration: '0.4s',
    animationIterationCount: 1,
  },
  errorFade: {
    animationName: [fadeKeyframes],
    animationDuration: '3.5s',
    animationIterationCount: 1,
  },

}))(LoginPuzzle)

// @flow

import React from 'react'
import DelayedMessage from './DelayedMessage'
import MessageType from './MessageType'
import { css, withStyles } from '../../withStyles';

import dog from '../AvatarPicker/avatars/user/dog.png'
import fox from '../AvatarPicker/avatars/user/fox.png'
import buffalo from '../AvatarPicker/avatars/user/buffalo.png'
import panda from '../AvatarPicker/avatars/user/panda.png'
import tiger from '../AvatarPicker/avatars/user/tiger.png'
import racoon from '../AvatarPicker/avatars/user/racoon.png'

import dogT from '../AvatarPicker/avatars/user/transparent/dog.png'
import foxT from '../AvatarPicker/avatars/user/transparent/fox.png'
import buffaloT from '../AvatarPicker/avatars/user/transparent/buffalo.png'
import pandaT from '../AvatarPicker/avatars/user/transparent/panda.png'
import tigerT from '../AvatarPicker/avatars/user/transparent/tiger.png'
import racoonT from '../AvatarPicker/avatars/user/transparent/racoon.png'

const userAvatarMapping = {
  [dog]: dogT,
  [fox]: foxT,
  [buffalo]: buffaloT,
  [panda]: pandaT,
  [tiger]: tigerT,
  [racoon]: racoonT
}

type Props = {
  styles: { [styleKey: string]: {} },
  message: {
    name: string,
    isUser?: bool,
    senderId: string
  },
  isNew: bool,
  name: string,
  timestamp: number,
  usersUiSettings: { [userKey: string]: { displayName: string, photoURL: string } },
  scrollToBottom: Function,
  markAsRead: Function,
  sendMessage: Function,
}

function Message(props: Props) {
  const { styles, message, scrollToBottom, isNew, markAsRead, sendMessage, usersUiSettings } = props
  setTimeout(scrollToBottom, 200)
  if(!message.isUser && isNew) {
    return (
      <DelayedMessage scrollToBottom={scrollToBottom} message={message} markAsRead={markAsRead}>
        <div {...css(styles.message, styles.message_botBert)}>
          <div {...css(styles.message, message.isUser ? styles.message_currentUser : styles.message_botBert)}>
            <div> {message.name} </div>
            <MessageType message={message} sendMessage={sendMessage} />
          </div>
        </div>
      </DelayedMessage>
    )
  }

  return (
    <div {...css(message.isUser ? styles.message_currentUser : styles.message_bot)}>
      <div {...css(styles.messageContainer, message.isUser ? styles.messageContainerUser : {})}>
        <div {...css(styles.avatar, message.isUser ? styles.avatar_right : styles.avatar_left)}>
          {
            message.isUser ?
              <img { ...css(styles.img) } src={`${usersUiSettings[message.senderId] ? userAvatarMapping[usersUiSettings[message.senderId].photoURL] : ''}`} alt="User avatar" />  :
              <img { ...css(styles.img) } src={`${usersUiSettings[message.senderId] ? usersUiSettings[message.senderId].photoURL : ''}`}  alt="Bot avatar" />
          }
        </div>
        <div {...css(styles.message, message.isUser ? styles.message_yellow : styles.message_red)}>
          <MessageType message={message} sendMessage={sendMessage} />
          <div {...css(styles.name, message.isUser ? styles.name_right: styles.name_left)}> { usersUiSettings[message.senderId] ? usersUiSettings[message.senderId].displayName : null } </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(({ extra, color, unit }): Object => ({
  message: {
    position: 'relative',
    padding: unit,
    marginBottom: unit * 2,
    borderRadius: extra.borderRadius,
    maxWidth: '65%',
    whiteSpace: 'pre-line',
    color: color.primary,
    fontSize: '15px',
    '@media (min-width: 768px)': {
      maxWidth: '90%',
    }
  },
  message_bot: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: color.white,
    flex: '0 0 auto',
  },
  message_currentUser: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
  },
  name: {
    color: 'black',
    position: 'absolute',
    bottom: -15,
    left: 0,
    fontSize: unit * 0.8,
    opacity: 0.5,
    width: '100%'
  },
  name_right: {
    textAlign: 'right'
  },
  name_left: {
    textAlign: 'left'
  },
  avatar: {
    width: unit * 5,
    height: unit * 5,
    flex: '0 0 auto',
    background: color.primary,
    borderRadius: '50%',
    zIndex: 1,
  },
  img: {
    width: '100%',
    borderRadius: '50%'
  },
  messageContainer: {
    display: 'flex',
    marginBottom: 12
  },
  messageContainerUser: {
    justifyContent: 'flex-end'
  },
  avatar_right: {
    order: 1,
    position: 'relative',
    left: 1,
    top: -6,
  },
  avatar_left: {
    order: 0,
    position: 'relative',
    left: -1,
    top: -6,
  },
  message_yellow: {
    background: color.orange,
    borderTopRightRadius: 0,
    textAlign: 'left',
    marginRight: -1,

    '@media (min-width: 768px)': {
      textAlign: 'right',
    },

    ':after': {
      content:"''",
      position: 'absolute',
      top: 0,
      right: -19,
      display: 'block',
      width: 20,
      height: 25,
      background: color.orange
    }
  },
  message_red: {
    background: color.red,
    borderTopLeftRadius: 0,
    textAlign: 'left',
    marginLeft: -1,

    ':after': {
      content:"''",
      position: 'absolute',
      top: 0,
      left: -29,
      display: 'block',
      width: 30,
      height: 25,
      background: color.red
    }

  }
}))(Message)

// @flow
import firebase from 'firebase'
import type { UserSettings } from './types'

type Providers = {
  google: any
}

const providers:Providers = {
  google: new firebase.auth.GoogleAuthProvider()
}


function authenticateUser(provider: string): any {
  if (provider) {
    return firebase.auth().signInWithPopup(providers[provider])
  }
  return firebase.auth().signInAnonymously().catch((error) => {
    console.log('error: ', error)
  })
}

function unAuthenticateUser() {
  return firebase.auth().signOut()
}

function updateUserProfile(settings: UserSettings) {
  const user = firebase.auth().currentUser
  return user.updateProfile(settings)
}

export {
  authenticateUser,
  unAuthenticateUser,
  updateUserProfile
}

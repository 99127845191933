import React from 'react'
import { css, withStyles } from '../../withStyles'

import Achievement from './Achievement'
import Loader from '../Loader/Loader'

function Achievements({ styles, achievements, unlockedAchievements }) {
  const numberOfUnlocked = Object.keys(unlockedAchievements).length
  const total = Object.keys(achievements).length

  return (
    <div {...css(styles.achievements)}>
      <div {...css(styles.stats)}>
      {`Progress ${numberOfUnlocked} / ${total}`}
      </div>
      <div>
        { Object.keys(achievements).map((key) => (
            <Achievement
              achievement={achievements[key]}
              unlocked={unlockedAchievements[key] && unlockedAchievements[key].unlocked}
              isNew={unlockedAchievements[key] && unlockedAchievements[key].new} />
          )
        )}
      </div>
    </div>
  )
}

export default withStyles(({ unit, color, extra }) => ({
  achievements: {
    backgroundColor: color.red,
  },
  stats: {
    color: color.white,
    fontSize: unit * 1.5,
    borderTop: '1px solid rgba(0,0,0,0.2)',
    padding: unit
  }
}))(Achievements)

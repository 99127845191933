import React from 'react'
import { css, withStyles } from '../../withStyles';

const ScoreBar = ({ percentage, styles }) => {
  return (
    <div {...css(styles.scoreBarContainer)}>
      <div style={{ width: percentage, overflow: 'hidden', borderRadius: 0}}>
        <div {...css(styles.scoreBar, styles.scoreBarFilled)}>
        </div>
        <div {...css(styles.scoreBar, styles.scoreBarTotal)}>
        </div>
      </div>
    </div>
  )
}

const transformKeyFrames = {
  '0%': {
    transform: 'translateX(-100%)',
  },

  '100%': {
    transform: 'translateX(0)',
  }
}

export default withStyles(({ extra, color, unit }) => ({
  scoreBar: {
    height: 20,
  },
  scoreBarContainer: {
    width: '90%',
    position: 'relative',
    zIndex: 1,
  },
  scoreBarFilled: {
    color: color.primary,
    background: '#d04a02',

    animationName: [transformKeyFrames],
    animationDuration: '2s',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },
  scoreBarTotal: {
    color: color.primary,
    background: '#ededf2',
    position: 'absolute',
    width: '100%',
    top: 0,
    zIndex: -1
  }
})) (ScoreBar)

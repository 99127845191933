// @flow

import React from 'react'
import { css, withStyles } from '../../withStyles'
import desktopbg from '../../images/background_blurred.jpg'

type Props = {
  handleOpenBrowser: Function,
  handleOpenChat: Function,
  handleOpenMusicPlayer: Function,
  handleOpenTrash: Function
}

function Desktop({
  styles,
  children,
  handleOpenBrowser,
  handleOpenChat,
  handleOpenMusicPlayer,
  handleOpenTrash
}) {

  const time = new Date()
  const hours = time.getHours()
  const minutes = time.getMinutes()

  return (
    <div {...css(styles.desktop)}>
      <div {...css(styles.clockBar)}>
        <p {...css(styles.clockText)}>{hours}:{minutes}</p>
      </div>
      <div { ...css(styles.content)}>
        {children}
      </div>
      <div {...css(styles.taskbar)}>
        <span { ...css(styles.taskbar_item)} onClick={handleOpenBrowser}>
          <i className={`fa fa-globe fa-2x`}></i>
        </span>
        <span { ...css(styles.taskbar_item)} onClick={handleOpenChat}>
          <i className={`fa fa-envelope fa-2x`}></i>
        </span>
        <span { ...css(styles.taskbar_item)} onClick={handleOpenMusicPlayer}>
          <i className={`fa fa-headphones fa-2x`}></i>
        </span>
        <span {...css(styles.taskbar_item, styles.taskbar_item_last)} onClick={handleOpenTrash}>
          <i  className={`fa fa-trash fa-2x`}></i>
        </span>
      </div>
    </div>

  )
}

export default withStyles(({ color, unit }) => ({
  desktop: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    height: '100vh',
  },
  clockBar: {
    display: 'none',
    width: '100%',
    height: '30px',
    background: '#eeedee',
    background: 'linear-gradient(to bottom, #eeedee 0%,#dad9d9 99%)',
    borderBottom: '1px solid #999FA3',
    lineHeight: '30px',
    padding: '0 10px 0 0',
  },
  clockText: {
    color: '#000',
    textAlign: 'right',
    color: '#626469',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.5)',
    fontSize: '15px',
  },
  topbar: {
    textAlign: 'right',
    background: color.primary,
    width: '100%',
    padding: unit / 2
  },
  content: {
    height: '100%',
    width: '100%',
    position: 'relative',
    zIndex: 1
  },
  taskbar: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    width: '80%',
    background: color.primary,
    flexGrow: 0,
    display: 'none',
  },
  taskbar_item: {
    cursor: 'pointer',
    padding: unit,

    ':hover': {
      color: color.red
    }
  },
  taskbar_item_last: {
    marginLeft: 'auto'
  }
}))(Desktop)


import React, { Component } from 'react'
import { css, withStyles } from '../../withStyles';

class Timer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      triggered: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ triggered: true })
    }, 100)
  }

  render() {
    const { currentTime, styles } = this.props
    return (
      <div { ...css(styles.container) }>
        <div { ...css(styles.timerbar, this.state.triggered && styles.timerbar_triggered) }>  </div>
      </div>
    )
  }
}

export default withStyles(({ color, extra, unit }) => ({
  container: {
    width: '100%',
    position: 'absolute'
  },
  timerbar: {
    width: '100%',
    height: 11,
    transition: 'transform 10s linear',
    backgroundColor: color.orange
  },
  timerbar_triggered: {
    transform:  'scaleX(0)'
  }
}))(Timer)

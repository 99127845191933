// @flow

import {
  SET_CURRENT_UI,
  UI_COMPONENT_MOVED,
  QUICK_REPLIES_LOADED,
  TIMER_SET,
  TIMER_DECREASE
} from '../../events'

function toggleCurrentUi({ uiKey }: { uiKey: string }) {
  return {
    type: SET_CURRENT_UI,
    payload: {
      uiKey
    }
  }
}

function moveUiComponent({ uiKey, x, y }: { uiKey: string, x: number, y: number }) {
  return {
    type: UI_COMPONENT_MOVED,
    payload: {
      uiKey,
      x,
      y
    }
  }
}

function quickRepliesLoaded({ conversationKey, replies, modifier }: { conversationKey: string, replies: Array<string>, modifier: string }) {
  return {
    type: QUICK_REPLIES_LOADED,
    payload: {
      conversationKey,
      replies,
      modifier
    }
  }
}

function timerSet({ conversationKey }: { conversationKey: string }) {
  return {
    type: TIMER_SET,
    payload: {
      conversationKey
    }
  }
}

function timerDecrease({ conversationKey }: { conversationKey: string }) {
  return {
    type: TIMER_DECREASE,
    payload: {
      conversationKey
    }
  }
}


export {
  toggleCurrentUi,
  moveUiComponent,
  quickRepliesLoaded,
  timerSet,
  timerDecrease
}

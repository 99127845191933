// @flow

import {
  MESSAGE_CHANGED,
  MESSAGE_CLEARED,
  MESSAGE_SENT,
  OLD_MESSAGE_LOADED,
  NEW_MESSAGE_LOADED,
  MESSAGES_LOADED,
  SET_CURRENT_CONVERSATION
} from '../../events'

import type { Message } from '../../types'

function messageChanged(message: string) {
  return {
    type: MESSAGE_CHANGED,
    payload: {
      message
    }
  }
}

function messageCleared({ chatId }: { chatId: string }) {
  return {
    type: MESSAGE_CLEARED,
    payload: {
      chatId
    }
  }
}

function messageSent() {
  return {
    type: MESSAGE_SENT,
    payload: {
      fo: 'baar'
    }
  }
}

function oldMessageLoaded(messageObject: Message) {
  const { conversationKey, message, messageKey } = messageObject
  return {
    type: OLD_MESSAGE_LOADED,
    payload: {
      conversationKey,
      message,
      messageKey
    }
  }
}

function newMessageLoaded(messageObject: Message) {
  const { conversationKey, message, messageKey } = messageObject
  return {
    type: NEW_MESSAGE_LOADED,
    payload: {
      conversationKey,
      message,
      messageKey
    }
  }
}

function messagesLoaded({ conversationKey, messages }: { conversationKey: string, messages: Array<Message>}) {
  return {
    type: MESSAGES_LOADED,
    payload: {
      conversationKey,
      messages,
    }
  }
}

function setCurrentConversation(conversationKey: string) {
  return {
    type: SET_CURRENT_CONVERSATION,
    payload : {
      conversationKey
    }
  }
}

export {
  messageChanged,
  messageCleared,
  messageSent,
  oldMessageLoaded,
  newMessageLoaded,
  messagesLoaded,
  setCurrentConversation
}
